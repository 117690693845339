import React from 'react';
import {Grid} from "@mui/material";
import {AnimationOnScroll} from "react-animation-on-scroll";

function AboutHero() {
    return <>
        <Grid container spacing={2} >
            <Grid item xs={12} md={6} style={{display:"flex" , justifyContent:"center", alignItems:"center" ,height:"40vh"}}>
                <AnimationOnScroll animateIn={"animate__fadeInRightBig"}>
                    <div className={"line"}/>
                    <h2>Who is BioRetis GmbH</h2>
                </AnimationOnScroll>
            </Grid>
            <Grid item xs={12} md={6}  style={{flexDirection: 'column'}}>
                <AnimationOnScroll animateIn={"animate__fadeInRightBig"} delay={500}>
                    <div className={"about_bioretis"}>
                        <p style={{width: '80%'}}>
                            BioRetis GmbH is an interdisciplinary group with expertise in the fields of
                        </p>
                        <br/>
                        <ul style={{paddingLeft:"5%"}}>
                            <li>Medicine</li>
                            <li>Mathematics</li>
                            <li>Bioinformatics</li>
                            <li>Biology</li>
                            <li>clinical trials, biobanks and documentation.</li>
                        </ul>
                        <br/>
                        <p>Our research focuses are: </p>
                        <br/>
                        <p>the analysis of</p>
                        <br/>
                        <ul style={{paddingLeft:"5%"}}>
                            <li>microarray gene expression data from Affymetrix-based studies</li>
                            <li>high-dimensional cytometry data from flow cytometric and mass spectrometric cell
                                investigations
                            </li>
                            <li>microbiome sequence data from typing sequencing</li>
                        </ul>
                        <br/>
                        <p>the development of</p>
                        <br/>
                        <ul style={{paddingLeft:"5%"}}>
                            <li>software/databases/applications for the collection of data in clinical trials</li>
                            <li>new algorithms for the exploitation of functional networks</li>
                            <li>the management of biobanks</li>
                        </ul>
                    </div>

                    </AnimationOnScroll>
            </Grid>
        </Grid>

    </>
}

export default AboutHero;