import React from 'react';
import {Grid} from "@mui/material";
import ImgCorousel from "./ImgCorousel";
import {AnimationOnScroll} from "react-animation-on-scroll";
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";

function ImageSectionRight({data, title , txt}) {
    return (
        <Grid container>
            <Grid item xs={12} md={6} className={"txt-container"} style={{display:"flex" , justifyContent:"center" , alignItems:"center"}}>
                <div className={"txt-wrapper-70"} style={{width:"70%" , height:"auto"}}>
                    <AnimationOnScroll animateIn={"animate__fadeInLeftBig"} >
                        <div className={"txt-container-column"} style={{display:"flex" , justifyContent:"center" , alignItems:"flex-start" , flexDirection:"column", marginBottom:"5%"}}>
                            <div className={"line"}></div>
                            <h3>{title}</h3>
                            <CustomHorizontalSpace space={20}/>

                        </div>
                        <div className={"txt-container-column"} style={{display:"flex" , justifyContent:"center" , alignItems:"flex-start" , flexDirection:"column"}}>
                            {txt.map( (line, index) => {
                                return <React.Fragment key={index}>
                                    <p >{line}</p>
                                    <br/>
                                </React.Fragment>
                            })}
                        </div>
                    </AnimationOnScroll>
                </div>
            </Grid>
            <Grid item xs={12} md={6} className={"img-container"}>
                <ImgCorousel data={data}/>
            </Grid>
        </Grid>
    );
}

export default ImageSectionRight;