import React from "react";
import {Grid} from "@mui/material";
import PropertyCard from "../../Cards/PropertyCard";
import bg from "../../SVG/Circle-270.svg"
import shield from "../../SVG/shield.svg"
import atom from "../../SVG/atom.svg"
import pyramid from "../../SVG/pyramid.svg"
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import {faChartBar} from "@fortawesome/free-regular-svg-icons";
import {Colors} from "../../../resources/Colors";

export function OverviewImmunoClustProps(){
    return <>
        <CustomHorizontalSpace space={100}/>

        <div style={{display:"flex" , justifyContent:"center" , alignItems:"center" , flexDirection:"column"}}>
            <h1>BioRetis GmbH</h1>
            <div className={"line"}></div>
        </div>

        <CustomHorizontalSpace space={100}/>
        <Grid container spacing={6} >
            <Grid item xs={12} >
                <Grid container direction={'row'} spacing={4} style={{backgroundImage: `url(${bg})` ,backgroundRepeat:'no-repeat', backgroundPosition:'center'}} >
                    <Grid item xs={6}>
                        <Grid container direction="column"
                              justifyContent="space-around"
                              alignItems="flex-end"
                              spacing={3}>
                            <Grid item>
                                <PropertyCard title={"Efficiency"} icon={faChartBar} color={Colors.bioretisBlue} />
                            </Grid>
                            <Grid item >
                                <PropertyCard title={"Stable"} svg={pyramid} svgWidth={70} />
                            </Grid>
                            <Grid item>
                                <CustomHorizontalSpace space={100}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container direction="column"
                              justifyContent="space-around"
                              alignItems="flex-start"
                              spacing={3}>

                            <Grid item >
                                <CustomHorizontalSpace space={100}/>
                            </Grid>
                            <Grid item >
                                <PropertyCard title={"Intelligence"} svg={atom} />
                            </Grid>
                            <Grid item>
                                <PropertyCard title={"Save"} svg={shield} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item xs={12} md={6} style={{display:'flex', justifyContent:'center' , alignItems:'center'}} >
                <Grid container direction={'column'}>
                    <Grid item xs={12} style={{display:'flex', justifyContent:'center',marginBottom:'30px'}}>
                        <h2> immunoClust Properties</h2>
                    </Grid>
                    <Grid item xs={12} style={{display:'flex', justifyContent:'space-between' , alignItems:'center', marginLeft:'25%', marginRight:'25%'}}>
                        <p align={'justify'}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                        </p>

                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
        <CustomHorizontalSpace space={100}/>





    </>


}export default OverviewImmunoClustProps