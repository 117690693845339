import React from 'react';
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import Title from "../../Title/Title";
import lock from "../../../resources/images/imattsmart-Vp3oWLsPOss-unsplash.jpg.webp"

function PrivacyPolice() {
        return <>
            <Title title={"Privacy Police"} img={lock}></Title>
            <CustomHorizontalSpace space={100}/>
            <div style={{width: '70%', marginLeft: '10%'}}>
                <div className={"line"}/>
            <h2 className="adsimple-311289262" id={'privacy'}>Datenschutzerklärung</h2><br/>
            <h3>Inhaltsverzeichnis</h3><br/>
            <ul>
                <li>
                    <a href="#einleitung-ueberblick">Einleitung und Überblick</a>
                </li>
                <li>
                    <a href="#anwendungsbereich">Anwendungsbereich</a>
                </li>
                <li>
                    <a href="#rechtsgrundlagen">Rechtsgrundlagen</a>
                </li>
                <li>
                    <a href="#kontaktdaten-verantwortliche">Kontaktdaten des Verantwortlichen</a>
                </li>
                <li>
                    <a href="#rechte-dsgvo">Rechte laut Datenschutzgrundverordnung</a>
                </li>
                <li>
                    <a href="#speicherdauer">Speicherdauer</a>
                </li>
                <li>
                    <a href="#kontaktdaten-datenschutzbeauftragter">Kontaktdaten des Datenschutzbeauftragten</a>
                </li>
                <li>
                    <a href="#sicherheit-datenverarbeitung">Sicherheit der Datenverarbeitung</a>
                </li>
                <li>
                    <a href="#kommunikation">Kommunikation</a>
                </li>
                <li>
                    <a href="#webhosting">Webhosting</a>
                </li>
                <li>
                    <a href="#cookies">Cookies</a>
                </li>
                <li>
                    <a href="#google-fonts-lokal-datenschutzerklaerung">Google Fonts Lokal Datenschutzerklärung</a>
                </li>
                <li>
                    <a href="#google-maps-datenschutzerklaerung">Google Maps Datenschutzerklärung</a>
                </li>
                <li>
                    <a href="#google-fonts-datenschutzerklaerung">Google Fonts Datenschutzerklärung</a>
                </li>
                <li>
                    <a href="#font-awesome-datenschutzerklaerung">Font Awesome Datenschutzerklärung</a>
                </li>
                <li>
                    <a href="#jquery-cdn-datenschutzerklaerung">jQuery CDN Datenschutzerklärung</a>
                </li>
                <li>
                    <a href="#bootstrapcdn-datenschutzerklaerung">BootstrapCDN Datenschutzerklärung</a>
                </li>
            </ul><br/>
            <h3 id="einleitung-ueberblick" className="adsimple-311289262">Einleitung und Überblick</h3><br/>
            <p>Wir haben diese Datenschutzerklärung (Fassung 07.07.2021-311289262) verfasst, um Ihnen gemäß der Vorgaben
                der <a className="adsimple-311289262"
                       href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311289262"
                       target="_blank" rel="noreferrer">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren
                nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als
                Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B.
                Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben.
                Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br/>
                <strong className="adsimple-311289262">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die
                wir über Sie verarbeiten.</p>
            <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese
                Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich
                beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong
                    className="adsimple-311289262">Begriffe leserfreundlich erklärt</strong>, <strong
                    className="adsimple-311289262">Links</strong> zu weiterführenden Informationen geboten und <strong
                    className="adsimple-311289262">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer
                und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten
                verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn
                man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft
                Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant
                und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br/>
                Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte
                verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf
                Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p><br/>
            <h3 id="anwendungsbereich" className="adsimple-311289262">Anwendungsbereich</h3><br/>
            <p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für
                alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit
                personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name,
                E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür,
                dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der
                Anwendungsbereich dieser Datenschutzerklärung umfasst:</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
                <li className="adsimple-311289262">Social Media Auftritte und E-Mail-Kommunikation</li>
                <li className="adsimple-311289262">mobile Apps für Smartphones und andere Geräte</li>
            </ul><br/>
            <p>
                <strong className="adsimple-311289262">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle
                Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet
                werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie
                gegebenenfalls gesondert informieren.</p><br/>
            <h3 id="rechtsgrundlagen" className="adsimple-311289262">Rechtsgrundlagen</h3><br/>
            <p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen
                Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns
                ermöglichen, personenbezogene Daten zu verarbeiten.<br/>
                Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND
                DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online
                auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className="adsimple-311289262"
                                                               href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&amp;tid=311289262"
                                                               target="_blank"
                                                               rel="noreferrer">https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32016R0679</a> nachlesen.
            </p>
            <p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p><br/>
            <ol>
                <li className="adsimple-311289262">
                    <strong className="adsimple-311289262">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie
                    haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre
                    die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.
                </li>
                <li className="adsimple-311289262">
                    <strong className="adsimple-311289262">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen
                    Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir
                    zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene
                    Informationen.
                </li>
                <li className="adsimple-311289262">
                    <strong className="adsimple-311289262">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c
                    DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel
                    sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel
                    personenbezogene Daten.
                </li>
                <li className="adsimple-311289262">
                    <strong className="adsimple-311289262">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f
                    DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die
                    Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere
                    Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein
                    berechtigtes Interesse.
                </li>
            </ol><br/>
            <p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher
                Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche
                Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p><br/>
            <p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">In <strong className="adsimple-311289262">Österreich</strong> ist dies
                    das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong
                        className="adsimple-311289262">Datenschutzgesetz</strong>), kurz <strong
                        className="adsimple-311289262">DSG</strong>.
                </li>
                <li className="adsimple-311289262">In <strong className="adsimple-311289262">Deutschland</strong> gilt
                    das <strong className="adsimple-311289262">Bundesdatenschutzgesetz</strong>, kurz<strong
                        className="adsimple-311289262"> BDSG</strong>.
                </li>
            </ul><br/>
            <p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden
                Abschnitten darüber.</p><br/>
            <h3 id="kontaktdaten-verantwortliche" className="adsimple-311289262">Kontaktdaten des Verantwortlichen</h3><br/>
            <p>Sollten Sie Fragen zum Datenschutz haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person
                bzw. Stelle:<br/>
                <br/>
                BioRetis GmbH<br/>
                Warägerweg 10 <br/>
                13595 Berlin<br/>
                Deutschland<br/>
                <br/>
                <span className="adsimple-311289262"
                      style={{fontWeight: "400"}}>Vertretungsberechtigt: Bettina Johannes</span>
                <br/>
                E-Mail: <a className="adsimple-311289262"
                           href="mailto:bettina.johannes@bioretis.com">bettina.johannes@bioretis.com</a>
            </p><br/>
            <h3 id="rechte-dsgvo" className="adsimple-311289262">Rechte laut Datenschutzgrundverordnung</h3><br/>
            <p>Laut Artikel 13 DSGVO stehen Ihnen die folgenden Rechte zu, damit es zu einer fairen und transparenten
                Verarbeitung von Daten kommt:</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten
                    von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und
                    die folgenden Informationen zu erfahren:<br/>
                    <ul className="adsimple-311289262" style={{marginLeft:'30px'}}>
                        <li className="adsimple-311289262">zu welchem Zweck wir die Verarbeitung durchführen;</li>
                        <li className="adsimple-311289262">die Kategorien, also die Arten von Daten, die verarbeitet
                            werden;
                        </li>
                        <li className="adsimple-311289262">wer diese Daten erhält und wenn die Daten an Drittländer
                            übermittelt werden, wie die Sicherheit garantiert werden kann;
                        </li>
                        <li className="adsimple-311289262">wie lange die Daten gespeichert werden;</li>
                        <li className="adsimple-311289262">das Bestehen des Rechts auf Berichtigung, Löschung oder
                            Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;
                        </li>
                        <li className="adsimple-311289262">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links
                            zu diesen Behörden finden Sie weiter unten);
                        </li>
                        <li className="adsimple-311289262">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben
                            haben;
                        </li>
                        <li className="adsimple-311289262">ob Profiling durchgeführt wird, ob also Daten automatisch
                            ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.
                        </li>
                    </ul>
                </li>
                <li className="adsimple-311289262">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was
                    bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.
                </li>
                <li className="adsimple-311289262">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf
                    Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.
                </li>
                <li className="adsimple-311289262">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der
                    Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.
                </li>
                <li className="adsimple-311289262">Sie haben laut Artikel 19 DSGVO das Recht auf Datenübertragbarkeit, was
                    bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.
                </li>
                <li className="adsimple-311289262">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach
                    Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
                    <ul className="adsimple-311289262" style={{marginLeft:'30px'}}>
                        <li className="adsimple-311289262">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e
                            (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f
                            (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir
                            prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.
                        </li>
                        <li className="adsimple-311289262">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie
                            jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach
                            nicht mehr für Direktmarketing verwenden.
                        </li>
                        <li className="adsimple-311289262">Werden Daten verwendet, um Profiling zu betreiben, können Sie
                            jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach
                            nicht mehr für Profiling verwenden.
                        </li>
                    </ul>
                </li>
                <li className="adsimple-311289262">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer
                    ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung
                    unterworfen zu werden.
                </li>
            </ul> <br/>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
                datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der
                Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a
                    className="adsimple-311289262" href="https://www.dsb.gv.at/?tid=311289262" target="_blank"
                    rel="noreferrer">https://www.dsb.gv.at/</a> finden und für Deutschland können Sie sich an die <a
                    className="adsimple-311289262" href="https://www.bfdi.bund.de" target="_blank"
                    rel="noreferrer">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden.</p><br/>
            <p>
                <strong className="adsimple-311289262">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die
                oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p><br/>
            <h3 id="speicherdauer" className="adsimple-311289262">Speicherdauer</h3><br/>
            <p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer
                Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet,
                dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden
                ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des
                ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
            <p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden
                die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
            <p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere
                Informationen dazu haben.</p><br/>
            <h3 id="kontaktdaten-datenschutzbeauftragter" className="adsimple-311289262">Kontaktdaten des
                Datenschutzbeauftragten</h3><br/>
            <p>Nachfolgend finden Sie die Kontaktdaten unseres Datenschutzbeauftragten.</p>
            <p>E-Mail: <a className="adsimple-311289262"
                          href="mailto:bettina.johannes@bioretis.com">bettina.johannes@bioretis.com</a>
            </p><br/>
            <h3 id="sicherheit-datenverarbeitung" className="adsimple-311289262">Sicherheit der Datenverarbeitung</h3><br/>
            <p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen
                umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch
                machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf
                persönliche Informationen schließen können.</p>
            <p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware
                (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden
                gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p><br/>
            <h3 id="tls-verschluesselung-https" className="adsimple-311289262">TLS-Verschlüsselung mit https</h3><br/>
            <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
                Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im
                Internet zu übertragen.<br/>
                Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert
                ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
            <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch
                Technikgestaltung <a className="adsimple-311289262"
                                     href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=311289262"
                                     target="_blank" rel="noreferrer">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von
                TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet,
                können wir den Schutz vertraulicher Daten sicherstellen.<br/>
                Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img
                    src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg"
                    width="17"
                    height="18" alt={"key"}/> links
                oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https
                (anstatt http) als Teil unserer Internetadresse.<br/>
                Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext
                Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p><br/>
            <h3 id="kommunikation" className="adsimple-311289262">Kommunikation</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">Kommunikation Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br/>
                        &#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten.
                        Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br/>
                        &#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br/>
                        &#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b
                        DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur
                Verarbeitung personenbezogener Daten kommen.</p>
            <p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden
                Geschäftsvorgangs verarbeitet. Die Daten während ebensolange gespeichert bzw. so lange es das Gesetz
                vorschreibt.</p><br/>
            <h3 className="adsimple-311289262">Betroffene Personen</h3><br/>
            <p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege
                den Kontakt zu uns suchen.</p><br/>
            <h3 className="adsimple-311289262">Telefon</h3><br/>
            <p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten
                Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im
                Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht,
                sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p><br/>
            <h3 className="adsimple-311289262">E-Mail</h3><br/>
            <p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer,
                Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die
                Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p><br/>
            <h3 className="adsimple-311289262">Online Formulare</h3><br/>
            <p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und
                gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der
                Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlagen</h3><br/>
            <p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung
                    Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;
                </li>
                <li className="adsimple-311289262">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für
                    die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder
                    wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots,
                    verarbeiten;
                </li>
                <li className="adsimple-311289262">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen
                    Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind
                    gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber
                    notwendig, um die Kommunikation effizient betreiben zu können.
                </li>
            </ul><br/>
            <h3 id="webhosting" className="adsimple-311289262">Webhosting</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">Webhosting Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Besucher der Website<br/>
                        &#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br/>
                        &#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und
                        weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting
                        Provider.<br/>
                        &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <h3 className="adsimple-311289262">Was ist Webhosting?</h3><br/>
            <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene
                Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten
                möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit
                aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten
                Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
            <p>Wenn Sie eine Website auf einem Bildschirm ansehen möchten, verwenden Sie dafür ein Programm, das sich
                Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla
                Firefox und Apple Safari.</p>
            <p>Dieser Webbrowser muss sich zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem
                Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der
                Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen
                damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites.</p>
            <p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Smartphone) und während der
                Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen.
                Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang
                speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
            <p>Zur Veranschaulichung:</p>
            <p>
                <img  src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg"
                      alt="Browser und Webserver" width="100%"/>
            </p><br/>
            <h3 className="adsimple-311289262">Warum verarbeiten wir personenbezogene Daten?</h3><br/>
            <p>Die Zwecke der Datenverarbeitung sind:</p><br/>
            <ol>
                <li className="adsimple-311289262">Professionelles Hosting der Website und Absicherung des Betriebs</li>
                <li className="adsimple-311289262">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
                <li className="adsimple-311289262">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres
                    Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen
                </li>
            </ol><br/>
            <h3 className="adsimple-311289262">Welche Daten werden verarbeitet?</h3><br/>
            <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf
                dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B.
                    https://www.beispielwebsite.de/beispielunterseite.html?tid=311289262)
                </li>
                <li className="adsimple-311289262">Browser und Browserversion (z. B. Chrome 87)</li>
                <li className="adsimple-311289262">das verwendete Betriebssystem (z. B. Windows 10)</li>
                <li className="adsimple-311289262">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                    https://www.beispielquellsite.de/vondabinichgekommen.html/)
                </li>
                <li className="adsimple-311289262">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen
                    wird (z. B. COMPUTERNAME und 194.23.43.121)
                </li>
                <li className="adsimple-311289262">Datum und Uhrzeit</li>
                <li className="adsimple-311289262">in Dateien, den sogenannten Webserver-Logfiles</li>
            </ul><br/>
            <h3 className="adsimple-311289262">Wie lange werden Daten gespeichert?</h3><br/>
            <p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir
                geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
                rechtswidrigem Verhalten von Behörden eingesehen werden.</p><br/>
            <p>
                <strong className="adsimple-311289262">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma,
                die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre
                Daten nicht ohne Zustimmung weiter!</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6
                Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei
                einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und
                Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p><br/>
            <h3 id="cookies" className="adsimple-311289262">Cookies</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">Cookies Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Besucher der Website<br/>
                        &#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim
                        Hersteller der Software, der das Cookie setzt.<br/>
                        &#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie
                        weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br/>
                        &#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren
                        variieren<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <h3 className="adsimple-311289262">Was sind Cookies?</h3><br/>
            <p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br/>
                Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
                Datenschutzerklärung besser verstehen.</p>
            <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
                Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine
                Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
            <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden
                Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
                Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer
                gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi
                dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei
                der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
            <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
                Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
                Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen
                die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen
                wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
            <p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem
                Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der
                Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
            <p><br/>
                <img  src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg"
                      alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%"/>
            </p><br/>
            <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
                unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt.
                Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
                Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
                enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres
                PCs zugreifen.</p><br/>
            <p>So können zum Beispiel Cookie-Daten aussehen:</p><br/>
            <p>
                <strong className="adsimple-311289262">Name:</strong> _ga<br/>
                <strong className="adsimple-311289262">Wert:</strong> GA1.2.1326744211.152311289262-9<br/>
                <strong className="adsimple-311289262">Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br/>
                <strong className="adsimple-311289262">Ablaufdatum:</strong> nach 2 Jahren</p><br/>
            <p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">Mindestens 4096 Bytes pro Cookie</li>
                <li className="adsimple-311289262">Mindestens 50 Cookies pro Domain</li>
                <li className="adsimple-311289262">Mindestens 3000 Cookies insgesamt</li>
            </ul><br/>
            <h3 className="adsimple-311289262">Welche Arten von Cookies gibt es?</h3><br/>
            <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den
                folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
                verschiedenen Arten von HTTP-Cookies eingehen.</p><br/>
            <p>Man kann 4 Arten von Cookies unterscheiden:</p><br/>
            <p>
                <strong className="adsimple-311289262">Unerlässliche Cookies<br/>
                </strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel
                braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten
                weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst
                wenn der User sein Browserfenster schließt.</p><br/>
            <p>
                <strong className="adsimple-311289262">Zweckmäßige Cookies<br/>
                </strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt.
                Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen
                Browsern gemessen.</p><br/>
            <p>
                <strong className="adsimple-311289262">Zielorientierte Cookies<br/>
                </strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene
                Standorte, Schriftgrößen oder Formulardaten gespeichert.</p><br/>
            <p>
                <strong className="adsimple-311289262">Werbe-Cookies<br/>
                </strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell
                angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p><br/>
            <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie
                zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
            <p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a
                className="adsimple-311289262" href="https://tools.ietf.org/html/rfc6265" target="_blank"
                rel="nofollow noopener noreferrer">https://tools.ietf.org/html/rfc6265</a>, dem Request for Comments der Internet
                Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p><br/>
            <h3 className="adsimple-311289262">Zweck der Verarbeitung über Cookies</h3><br/>
            <p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw.
                beim Hersteller der Software, die das Cookie setzt.</p><br/>
            <h3 className="adsimple-311289262">Welche Daten werden verarbeitet?</h3><br/>
            <p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert
                werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden
                Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p><br/>
            <h3 className="adsimple-311289262">Speicherdauer von Cookies</h3><br/>
            <p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach
                weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
            <p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies
                jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf
                einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit
                der Speicherung bis dahin unberührt bleibt.</p><br/>
            <h3 className="adsimple-311289262">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3><br/>
            <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher
                Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur
                teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen
                Cookies zulassen.</p><br/>
            <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
                Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
            <p>
                <a className="adsimple-311289262" href="https://support.google.com/chrome/answer/95647?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311289262" target="_blank"
                   rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                    auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p><br/>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
                informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
                Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen
                die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren
                Chrome&#8221; im Falle eines Chrome Browsers.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von
                Cookies eine <strong className="adsimple-311289262">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO)
                von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf
                diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des
                Telekommunikationsgesetzes (TKG). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht
                umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des
                Telemediengesetzes (TMG).</p>
            <p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt. bestehen <strong
                className="adsimple-311289262">berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den
                meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme
                Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
            <p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer
                Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
            <p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte
                Software Cookies verwendet.</p><br/>
            <h3 id="google-fonts-lokal-datenschutzerklaerung" className="adsimple-311289262">Google Fonts Lokal
                Datenschutzerklärung</h3><br/>
            <p>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das
                Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben
                die Google-Schriftarten lokal, d.h. auf unserem Webserver &#8211; nicht auf den Servern von
                Google &#8211; eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine
                Datenübertragung oder Speicherung.</p><br/>
            <h3 className="adsimple-311289262">Was sind Google Fonts?</h3><br/>
            <p>Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis
                mit über 800 Schriftarten, die <a className="adsimple-311289262"
                                                  href="https://de.wikipedia.org/wiki/Google_LLC?tid=311289262">Google</a> kostenlos
                bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen.
                Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die
                Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden
                keine Daten an Google Fonts weiter.</p><br/>
            <h3 id="google-maps-datenschutzerklaerung" className="adsimple-311289262">Google Maps Datenschutzerklärung</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">Google Maps Datenschutzerklärung Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Besucher der Website<br/>
                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa eingegebene Suchbegriffe, Ihre IP-Adresse und auch die
                        Breiten- bzw. Längenkoordinaten.<br/>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
                        &#x1f4c5; Speicherdauer: abhängig von den gespeicherten Daten<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <h3 className="adsimple-311289262">Was ist Google Maps?</h3><br/>
            <p>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das
                Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
                verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre
                Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den
                Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir
                diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden
                können.</p>
            <p>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC,
                ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen
                suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere
                Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte
                eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche
                als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen
                Satellitenbildern sind sehr genaue Darstellungen möglich.</p><br/>
            <h3 className="adsimple-311289262">Warum verwenden wir Google Maps auf unserer Website?</h3><br/>
            <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf
                unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten
                Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die
                Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für
                Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die
                Bereitstellung von Google Maps Teil unseres Kundenservice.</p><br/>
            <h3 className="adsimple-311289262">Welche Daten werden von Google Maps gespeichert?</h3><br/>
            <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und
                speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten-
                bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse
                gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie
                darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden
                haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über
                Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und
                individuelle, personalisierte Werbung für Sie bereitzustellen.</p><br/>
            <p>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p><br/>
            <p>
                <strong className="adsimple-311289262">Name:</strong> NID<br/>
                <strong className="adsimple-311289262">Wert:</strong> 188=h26c1Ktha7fCQTx8rXgLyATyITJ311289262-5<br/>
                <strong className="adsimple-311289262">Verwendungszweck:</strong> NID wird von Google verwendet, um
                Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am
                häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer
                maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre
                persönlichen Einstellungen für Werbezwecke zu sammeln.<br/>
                <strong className="adsimple-311289262">Ablaufdatum:</strong> nach 6 Monaten</p>
            <p>
                <strong className="adsimple-311289262">Anmerkung:</strong> Wir können bei den Angaben der gespeicherten
                Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie
                auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich
                Google Maps eingebunden war.</p><br/>
            <h3 className="adsimple-311289262">Wie lange und wo werden die Daten gespeichert?</h3><br/>
            <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in
                Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau
                nachlesen wo sich die Google-Rechenzentren befinden: <a className="adsimple-311289262"
                                                                        href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">https://www.google.com/about/datacenters/inside/locations/?hl=de</a>
            </p>
            <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und
                werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle
                Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die
                Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.</p>
            <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die
                Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum
                Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach
                9 bzw.18 Monaten löscht.</p><br/>
            <h3 className="adsimple-311289262">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3><br/>
            <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen
                zur Standortbestimmung und Web-/App-Aktivität &#8211; abhängig von Ihrer Entscheidung &#8211; entweder 3
                oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch
                jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen,
                müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und
                Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder
                ausschalten.</p>
            <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem
                welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie
                Sie Cookies in Ihrem Browser verwalten:</p>
            <p>
                <a className="adsimple-311289262" href="https://support.google.com/chrome/answer/95647?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311289262" target="_blank"
                   rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites
                    auf Ihrem Computer abgelegt haben</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Internet Explorer: Löschen und Verwalten von Cookies</a>
            </p>
            <p>
                <a className="adsimple-311289262"
                   href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=311289262"
                   target="_blank" rel="noopener noreferrer">Microsoft Edge: Löschen und Verwalten von Cookies</a>
            </p><br/>
            <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer
                informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob
                Sie es erlauben oder nicht.</p>
            <p>Bitte beachten Sie, dass bei der Verwendung dieses Tools Daten von Ihnen auch außerhalb der EU gespeichert
                und verarbeitet werden können. Die meisten Drittstaaten (darunter auch die USA) gelten nach derzeitigem
                europäischen Datenschutzrecht als nicht sicher. Daten an unsichere Drittstaaten dürfen also nicht einfach
                übertragen, dort gespeichert und verarbeitet werden, sofern es keine passenden Garantien (wie etwa
                EU-Standardvertragsklauseln) zwischen uns und dem außereuropäischen Dienstleister gibt.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Wenn Sie eingewilligt haben, dass Google Maps eingesetzt werden darf, ist die Rechtsgrundlage der
                entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
                    className="adsimple-311289262"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage
                für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Maps vorkommen kann,
                dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Maps zu verwenden, um unser Online-Service
                zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-311289262">Art. 6
                    Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Maps gleichwohl nur ein, soweit
                Sie eine Einwilligung erteilt haben.</p>
            <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau
                für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Google Maps.
                Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden.
                Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann
                ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Google, bei denen Sie ein
                Nutzerkonto haben, verknüpft werden.</p>
            <p>Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene
                Datenschutzerklärung des Unternehmens unter <a className="adsimple-311289262"
                                                               href="https://policies.google.com/privacy?hl=de"
                                                               target="_blank"
                                                               rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
            </p><br/>
            <h3 id="google-fonts-datenschutzerklaerung" className="adsimple-311289262">Google Fonts
                Datenschutzerklärung</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">Google Fonts Datenschutzerklärung Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Besucher der Website<br/>
                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen<br/>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
                        &#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <h3 className="adsimple-311289262">Was sind Google Fonts?</h3><br/>
            <p>Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google
                Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street
                Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
            <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
                Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden
                über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die
                Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein
                Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von
                Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und
                der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht,
                werden wir uns noch im Detail ansehen.</p>
            <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
                className="adsimple-311289262"
                href="https://de.wikipedia.org/wiki/Google_LLC?tid=311289262">Google</a> Ihren Nutzern kostenlos zu
                Verfügung stellen.</p>
            <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
                Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p><br/>
            <h3 className="adsimple-311289262">Warum verwenden wir Google Fonts auf unserer Website?</h3><br/>
            <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem
                eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu
                halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist
                speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt
                die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
                Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen
                Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch
                verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine
                plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla
                Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen
                Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google
                Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen
                können.</p><br/>
            <h3 className="adsimple-311289262">Welche Daten werden von Google gespeichert?</h3><br/>
            <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
                externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
                IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
                Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften
                nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als
                Datenübermittler im Softwarebereich.</p>
            <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
                gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
                veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet
                Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden.
                Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler
                nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
            <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
                Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des
                Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist
                nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p><br/>
            <h3 className="adsimple-311289262">Wie lange und wo werden die Daten gespeichert?</h3><br/>
            <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der
                EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
                Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart
                einer Webseite ändern kann.</p>
            <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
                Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
                werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später
                besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren,
                die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p><br/>
            <h3 className="adsimple-311289262">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3><br/>
            <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
                werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können,
                müssen Sie den Google-Support auf <a className="adsimple-311289262"
                                                     href="https://support.google.com/?hl=de&amp;tid=311289262">https://support.google.com/?hl=de&amp;tid=311289262</a> kontaktieren.
                Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
            <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
                können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
                rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a className="adsimple-311289262"
                                                                                      href="https://developers.google.com/fonts/faq?tid=311289262">https://developers.google.com/fonts/faq?tid=311289262</a>.
                Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen
                über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
                Informationen über gespeicherten Daten zu bekommen.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der
                entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
                    className="adsimple-311289262"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage
                für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann,
                dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser Online-Service
                zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-311289262">Art. 6
                    Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Font gleichwohl nur ein, soweit
                Sie eine Einwilligung erteilt haben.</p>
            <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau
                für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Google
                Fonts. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert
                werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen.
                Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Google, bei denen
                Sie ein Nutzerkonto haben, verknüpft werden.</p>
            <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
                auf <a className="adsimple-311289262"
                       href="https://policies.google.com/privacy?hl=de&amp;tid=311289262">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.
            </p><br/>
            <h3 id="font-awesome-datenschutzerklaerung" className="adsimple-311289262">Font Awesome
                Datenschutzerklärung</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">Font Awesome Datenschutzerklärung Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Besucher der Website<br/>
                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung<br/>
                        &#x1f4d3; Verarbeitete Daten: etwa IP-Adresse und und welche Icon-Dateien geladen werden<br/>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
                        &#x1f4c5; Speicherdauer: Dateien in identifizierbarer Form werden wenige Wochen gespeichert<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <h3 className="adsimple-311289262">Was ist Font Awesome?</h3><br/>
            <p>Wir verwenden auf unserer Website Font Awesome des amerikanischen Unternehmens Fonticons (307 S. Main St.,
                Suite 202, Bentonville, AR 72712, USA). Wenn Sie eine unserer Webseite aufrufen, wird die Web-Schriftart
                Font Awesome (im Speziellen Icons) über das Font Awesome Content Delivery Netzwerk (CDN) geladen. So werden
                die Texte bzw. Schriften und Icons auf jedem Endgerät passend angezeigt. In dieser Datenschutzerklärung
                gehen wir näher auf die Datenspeicherung und Datenverarbeitung durch diesen Service ein.</p>
            <p>Icons spielen für Websites eine immer wichtigere Rolle. Font Awesome ist eine Web-Schriftart, die speziell
                für Webdesigner und Webentwickler entwickelt wurde. Mit Font Awesome können etwa Icons mit Hilfe der
                Stylesheet-Sprache CSS nach Belieben skaliert und gefärbt werden. Sie ersetzen so alte Bild-Icons. Font
                Awesome CDN ist der einfachste Weg die Icons oder Schriftarten auf Ihre Website zu laden. Dafür mussten wir
                nur eine kleine Code-Zeile in unsere Website einbinden.</p><br/>
            <h3 className="adsimple-311289262">Warum verwenden wir Font Awesome auf unserer Website?</h3><br/>
            <p>Durch Font Awesome können Inhalte auf unserer Website besser aufbereitet werden. So können Sie sich auf
                unserer Website besser orientieren und die Inhalte leichter erfassen. Mit den Icons kann man sogar manchmal
                ganze Wörter ersetzen und Platz sparen. Da ist besonders praktisch, wenn wir Inhalte speziell für
                Smartphones optimieren. Diese Icons werden statt als Bild als HMTL-Code eingefügt. Dadurch können wir die
                Icons mit CSS genauso bearbeiten, wie wir wollen. Gleichzeitig verbessern wir mit Font Awesome auch unsere
                Ladegeschwindigkeit, weil es sich nur um HTML-Elemente handelt und nicht um Icon-Bilder. All diese Vorteile
                helfen uns, die Website für Sie noch übersichtlicher, frischer und schneller zu machen.</p><br/>
            <h3 className="adsimple-311289262">Welche Daten werden von Font Awesome gespeichert?</h3><br/>
            <p>Zum Laden von Icons und Symbolen wird das Font Awesome Content Delivery Network (CDN) verwendet. CDNs sind
                Netzwerke von Servern, die weltweit verteilt sind und es möglich machen, schnell Dateien aus der Nähe zu
                laden. So werden auch, sobald Sie eine unserer Seiten aufrufen, die entsprechenden Icons von Font Awesome
                bereitgestellt.</p>
            <p>Damit die Web-Schriftarten geladen werden können, muss Ihr Browser eine Verbindung zu den Servern des
                Unternehmens Fonticons, Inc. herstellen. Dabei wird Ihre IP-Adresse erkannt. Font Awesome sammelt auch Daten
                darüber, welche Icon-Dateien wann heruntergeladen werden. Weiters werden auch technische Daten wie etwa Ihre
                Browser-Version, Bildschirmauflösung oder der Zeitpunkt der ausgerufenen Seite übertragen.</p>
            <p>Aus folgenden Gründen werden diese Daten gesammelt und gespeichert:</p><br/>
            <ul className="adsimple-311289262">
                <li className="adsimple-311289262">um Content Delivery Netzwerke zu optimieren</li>
                <li className="adsimple-311289262">um technische Fehler zu erkennen und zu beheben</li>
                <li className="adsimple-311289262">um CDNs vor Missbrauch und Angriffen zu schützen</li>
                <li className="adsimple-311289262">um Gebühren von Font Awesome Pro-Kunden berechnen zu können</li>
                <li className="adsimple-311289262">um die Beliebtheit von Icons zu erfahren</li>
                <li className="adsimple-311289262">um zu wissen, welchen Computer und welche Software Sie verwenden</li>
            </ul><br/>
            <p>Falls Ihr Browser Web-Schriftarten nicht zulässt, wird automatisch eine Standardschrift Ihres PCs verwendet.
                Nach derzeitigem Stand unserer Erkenntnis werden keine Cookies gesetzt. Wir sind mit der
                Datenschutzabteilung von Font Awesome in Kontakt und geben Ihnen Bescheid, sobald wir näheres in Erfahrung
                bringen.</p><br/>
            <h3 className="adsimple-311289262">Wie lange und wo werden die Daten gespeichert?</h3><br/>
            <p>Font Awesome speichert Daten über die Nutzung des Content Delivery Network auf Servern auch in den
                Vereinigten Staaten von Amerika. Die CDN-Server befinden sich allerdings weltweit und speichern Userdaten,
                wo Sie sich befinden. In identifizierbarer Form werden die Daten in der Regel nur wenige Wochen gespeichert.
                Aggregierte Statistiken über die Nutzung von den CDNs können auch länger gespeichert werden.
                Personenbezogene Daten sind hier nicht enthalten.</p><br/>
            <h3 className="adsimple-311289262">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3><br/>
            <p>Font Awesome speichert nach aktuellem Stand unseres Wissens keine personenbezogenen Daten über die Content
                Delivery Netzwerke. Wenn Sie nicht wollen, dass Daten über die verwendeten Icons gespeichert werden, können
                Sie leider unsere Website nicht besuchen. Wenn Ihr Browser keine Web-Schriftarten erlaubt, werden auch keine
                Daten übertragen oder gespeichert. In diesem Fall wird einfach die Standard-Schrift Ihres Computers
                verwendet.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Wenn Sie eingewilligt haben, dass Font Awesome eingesetzt werden darf, ist die Rechtsgrundlage der
                entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
                    className="adsimple-311289262"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage
                für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Font Awesome vorkommen kann,
                dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Font Awesome zu verwenden, um unser
                Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong
                    className="adsimple-311289262">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen
                Font Awesome gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
            <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau
                für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch Font
                Awesome. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert
                werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen.
                Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von Font Awesome, bei
                denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
            <p>Wenn Sie mehr über Font Awesome und deren Umgang mit Daten erfahren wollen, empfehlen wir Ihnen die
                Datenschutzerklärung unter <a className="adsimple-311289262"
                                              href="https://fontawesome.com/privacy">https://fontawesome.com/privacy</a> und
                die Hilfeseite unter <a className="adsimple-311289262"
                                        href="https://fontawesome.com/help?tid=311289262">https://fontawesome.com/help</a>.
            </p><br/>
            <h3 id="jquery-cdn-datenschutzerklaerung" className="adsimple-311289262">jQuery CDN Datenschutzerklärung</h3><br/>
            <p>Um Ihnen unsere Website bzw. all unsere einzelnen Unterseiten (Webseiten) auf unterschiedlichen Geräten
                schnell und problemlos auszuliefern, nutzen wir Dienste von jQuery CDN des Unternehmens jQuery Foundation.
                jQuery wird über das Content Delivery Network (CDN) des amerikanischen Software-Unternehmens StackPath (LCC
                2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA) verteilt. Durch diesen Dienst werden personenbezogene
                Daten von Ihnen gespeichert, verwaltet und verarbeitet.</p>
            <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet
                miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei
                großen Lastspitzen schnell ausgeliefert werden.</p>
            <p>jQuery nutzt JavaScript-Bibliotheken, um unsere Website-Inhalte zügig ausliefern zu können. Dafür lädt ein
                CDN-Server die nötigen Dateien. Sobald eine Verbindung zum CDN-Server aufgebaut ist, wird Ihre IP-Adresse
                erfasst und gespeichert. Das geschieht nur, wenn diese Daten nicht schon durch einen vergangenen
                Websitebesuch in Ihrem Browser gespeichert sind.</p>
            <p>In den Datenschutz-Richtlinien von StackPath wird ausdrücklich erwähnt, dass StackPath aggregierte und
                anonymisierte Daten von diversen Diensten (wie eben auch jQuery) für die Erweiterung der Sicherheit und für
                eigene Dienste benutzen. Durch diese Daten können Sie als Person allerdings nicht identifiziert werden.</p><br/>
            <h3 className="adsimple-311289262">Widerspruchsrecht</h3><br/>
            <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen
                können Sie auch jederzeit Verantwortliche von jQuery kontaktieren.</p>
            <p>Wenn Sie nicht wollen, dass es zu einer Datenübertragung kommt, haben Sie immer auch die Möglichkeit
                Java-Scriptblocker wie beispielsweise <a className="adsimple-311289262" href="https://www.ghostery.com/de/"
                                                         target="_blank"
                                                         rel="follow noopener noreferrer">ghostery.com</a> oder <a
                    className="adsimple-311289262" href="https://noscript.net/" target="_blank"
                    rel="follow noopener noreferrer">noscript.net</a> zu installieren. Sie können aber auch einfach in Ihrem
                Browser die Ausführung von JavaScript-Codes deaktivieren. Wenn Sie sich für die Deaktivierung von
                JavaScript-Codes entscheiden, verändern sich auch die gewohnten Funktionen. So wird beispielsweise eine
                Website nicht mehr so schnell geladen.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Wenn Sie eingewilligt haben, dass jQuery CDN eingesetzt werden darf, ist die Rechtsgrundlage der
                entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
                    className="adsimple-311289262"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage
                für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch jQuery CDN vorkommen kann,
                dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, jQuery CDN zu verwenden, um unser Online-Service
                zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong
                    className="adsimple-311289262">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen
                jQuery CDN gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
            <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau
                für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch jQuery CDN.
                Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und gespeichert werden.
                Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne Daten nehmen. Es kann
                ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von jQuery CDN, bei denen Sie
                ein Nutzerkonto haben, verknüpft werden.</p>
            <p>Mehr Informationen zum Datenschutz bei StackPath finden Sie unter <a className="adsimple-311289262"
                                                                                    href="https://www.stackpath.com/legal/privacy-statement"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer">https://www.stackpath.com/legal/privacy-statement/</a> und
                zu jQuery unter <a className="adsimple-311289262"
                                   href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf"
                                   target="_blank"
                                   rel="follow noopener noreferrer">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Privacy-2019-11-15.pdf</a>.
            </p><br/>
            <h3 id="bootstrapcdn-datenschutzerklaerung" className="adsimple-311289262">BootstrapCDN
                Datenschutzerklärung</h3><br/>
            <table border="1" cellPadding="15">
                <tbody>
                <tr>
                    <td>
                        <strong className="adsimple-311289262">BootstrapCDN Datenschutzerklärung Zusammenfassung</strong>
                        <br/>
                        &#x1f465; Betroffene: Besucher der Website<br/>
                        &#x1f91d; Zweck: Optimierung unserer Serviceleistung (um die Website schneller laden zu können)<br/>
                        &#x1f4d3; Verarbeitete Daten: Daten wie etwa Ihre IP-Adresse, Browsertyp, Browserversion, welche
                        Webseite geladen wird oder Uhrzeit und Datum des Seitenbesuchs<br/>
                        Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br/>
                        &#x1f4c5; Speicherdauer: meisten werden die Daten solange gespeichert, bis sie zur Erfüllung der
                        Dienstleistung nicht mehr benötigt werden<br/>
                        &#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f
                        DSGVO (Berechtigte Interessen)
                    </td>
                </tr>
                </tbody>
            </table><br/>
            <h3 className="adsimple-311289262">Was ist BootstrapCDN?</h3><br/>
            <p>Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher
                ausliefern zu können, nutzen wir das Content Delivery Network (CDN) BootstrapCDN, ein Open-Source-Dienst
                von <a className="adsimple-311289262" href="https://www.jsdelivr.com/" target="_blank"
                       rel="follow noopener noreferrer">jsdelivr.com</a> des polnischen Software-Unternehmens ProspectOne,
                Królewska 65A/1, 30-081, Kraków, Polen. Ein Content Delivery Network (CDN) ist ein Netzwerk regional
                verteilter Server, die über das Internet miteinander verbunden sind. Durch dieses Netzwerk können Inhalte,
                speziell sehr große Dateien, auch bei großen Lastspitzen schnell ausgeliefert werden.</p><br/>
            <h3 className="adsimple-311289262">Warum verwenden wir BootstrapCDN?</h3><br/>
            <p>Selbstverständlich wollen wir Ihnen mit unserer Website einen umfassenden und gut funktionierenden Service
                bieten. Dazu zählt auch eine schnelle Website. Mit jsdelivr.com-CDN kann unsere Website bei Ihnen viel
                schneller geladen werden. Besonders hilfreich wird der Einsatz von jsdelivr.com-CDN für User aus dem
                Ausland, da hier die Seite von einem Server in der Nähe ausgeliefert werden kann.</p><br/>
            <h3 className="adsimple-311289262">Welche Daten werden durch BootstrapCDN verarbeitet?</h3><br/>
            <p>BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden.
                Lädt nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum
                Bootstrap-CDN-Server übermittelt. Es können also auch personenbezogene Daten gesendet und gespeichert
                werden. BootstrapCDN kann somit Userdaten wie IP-Adresse, Browsertyp, Browserversion, welche Webseite
                geladen wird oder Uhrzeit und Datum des Seitenbesuchs sammeln und speichern. In der Datenschutzerklärung von
                BootstrapCDN bzw. <a className="adsimple-311289262" href="https://www.jsdelivr.com/" target="_blank"
                                     rel="follow noopener noreferrer">jsdelivr.com</a> wird ausdrücklich darauf hingewiesen,
                dass das Unternehmen keine Cookies oder andere Tracking-Services verwendet.</p><br/>
            <h3 className="adsimple-311289262">Wie lange und wo werden die Daten gespeichert?</h3><br/>
            <p>BootstrapCDN hat Server in verschiedenen Ländern verteilt und Ihre Daten können auch außerhalb des
                Europäischen Wirtschaftsraums gespeichert werden. BootstrapCDN bewahrt personenbezogene Daten, die in
                unserem Auftrag verarbeitet werden, so lange auf, wie es für die Erbringung angebotener Dienstleistungen
                erforderlich ist, wie es für die Erfüllung rechtlichen Verpflichtungen, die Beilegung von Streitigkeiten und
                die Durchsetzung der Vereinbarungen notwendig ist.</p><br/>
            <h3 className="adsimple-311289262">Widerspruchsrecht</h3><br/>
            <p>Sie haben immer das Recht auf Auskunft, Berichtigung und Löschung Ihrer personenbezogenen Daten. Bei Fragen
                können Sie auch jederzeit Verantwortliche von BootstrapCDN kontaktieren.</p>
            <p>Wenn Sie diese Datenübertragung unterbinden wollen, können Sie einen JavaScript-Blocker (siehe
                beispielsweise <a className="adsimple-311289262" href="https://noscript.net/" target="_blank"
                                  rel="noopener noreferrer">https://noscript.net/</a>) installieren oder in Ihrem Browser
                die Ausführung von JavaScript-Codes deaktivieren. Bitte beachten Sie aber, dass dadurch die Website nicht
                mehr das gewohnte Service (wie etwa schnelle Ladegeschwindigkeit) bieten kann.</p><br/>
            <h3 className="adsimple-311289262">Rechtsgrundlage</h3><br/>
            <p>Wenn Sie eingewilligt haben, dass BootstrapCDN eingesetzt werden darf, ist die Rechtsgrundlage der
                entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong
                    className="adsimple-311289262"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage
                für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch BootstrapCDN vorkommen kann,
                dar.</p>
            <p>Von unserer Seite besteht zudem ein berechtigtes Interesse, BootstrapCDN zu verwenden, um unser
                Online-Service zu optimieren und sicherer zu machen. Die dafür entsprechende Rechtsgrundlage ist <strong
                    className="adsimple-311289262">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen
                BootstrapCDN gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
            <p>Wir weisen darauf hin, dass nach Meinung des Europäischen Gerichtshofs derzeit kein angemessenes Schutzniveau
                für den Datentransfer in die USA besteht. Die Datenverarbeitung geschieht im Wesentlichen durch
                BootstrapCDN. Dies kann dazu führen, dass gegebenenfalls Daten nicht anonymisiert verarbeitet und
                gespeichert werden. Ferner können gegebenenfalls US-amerikanische staatliche Behörden Zugriff auf einzelne
                Daten nehmen. Es kann ferner vorkommen, dass diese Daten mit Daten aus möglichen anderen Diensten von
                BootstrapCDN, bei denen Sie ein Nutzerkonto haben, verknüpft werden.</p>
            <p>Mehr Informationen zum Datenschutz bei BootstrapCDN finden Sie auf <a className="adsimple-311289262"
                                                                                     href="https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net?tid=311289262"
                                                                                     target="_blank"
                                                                                     rel="follow noopener noreferrer">https://www.jsdelivr.com/terms/privacy-policy-jsdelivr-net.</a>
            </p><br/>
            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p style={{marginTop:"15px"}}>Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/">Datenschutz
                Generator</a> von AdSimple</p>

            </div>
            <CustomHorizontalSpace space={100}/>
        </>
}

export default PrivacyPolice;