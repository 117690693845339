import React from "react";
import { Link } from "react-router-dom";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Colors} from "../../resources/Colors";

const ButtonMailTo = ({ mailto, label }) => {
    const [over, setOver] = React.useState(false);
    return (
        <Link
            to='#'
            onClick={(e) => {
                window.location = mailto;
                e.preventDefault();
            }}
            onMouseOver={() => setOver(true)}
            onMouseLeave={() => setOver(false)}
        >
            <FontAwesomeIcon icon={faEnvelope} color={over ? Colors.bioretisContrast  : Colors.white}/> {label}
        </Link>
    );
};

export default ButtonMailTo;