import React, {useState} from "react";
import {HashLink as Link} from "react-router-hash-link";
import logo from "../../resources/images/bioretis-icon.png";
import {faTimes, faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Button} from "../Buttons/Button";
import "./Navbar.css";

function Navbar() {

    const [click, setClick] = useState(false);
    const [button, setButton] = useState(false);
    const [colorChange, setColorchange] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    React.useEffect(() => {
        if(click){
            setColorchange(true)
        }else if(window.scrollY <= 300){
            setColorchange(false)
        }
    },[click])

    React.useEffect( () => {
        showButton()
    },[])

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    }

    const changeNavbarColor = () =>{
        if(window.scrollY >= 300){
            setColorchange(true);
        }
        else{
            setColorchange(false);
        }
    };

    window.addEventListener('scroll', changeNavbarColor);

    window.addEventListener('resize' , showButton);

    return (
        <>
            <nav className={colorChange ? 'navbar navbar-colored' : 'navbar'}>
                <div className={"navbar-container"}>
                    <Link to={'/#home'} className={'navbar-logo'} onClick={() => console.log("click")}>
                        <img src={logo} alt="Logo" width={"50px"}/>
                        <h5>BioRetis</h5>
                    </Link>
                    <div className={"menu-icon"} onClick={handleClick}>
                        <FontAwesomeIcon color={'#fff'} icon={click ? faTimes : faBars}/>
                    </div>
                    <ul className={click ? "nav-menu active" : "nav-menu"}>
                        <li className={"nav-item"}>
                            <Link to={"/#home"} className={"nav-links "} onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/about"} className={"nav-links "} onClick={closeMobileMenu}>
                                About
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/immunoclust-demo"} className={"nav-links "} onClick={closeMobileMenu}>
                                ICAP Demo
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/publications"} className={"nav-links "} onClick={closeMobileMenu}>
                                Publications
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            {!button && <Link to={"/#contact"} className={"nav-links-mobile"} onClick={closeMobileMenu}>
                                Contact
                            </Link>}
                        </li>
                    </ul>
                    <div className={"nav-btn"}>
                        {button && <Button buttonStyle={"btn--outline"} onClick={() => window.location.href='/#contact'}>Contact</Button>}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar