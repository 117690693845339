import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function ImgCorousel({data}) {
    return (<>
        <div style={{width:"100%" , height:"100%"}}>
            <Carousel>
                {data.map((img , index) => {
                    return <div key={index}>
                        <img src={img.url} alt={img.legend} />
                        <p className="legend">{img.legend}</p>
                    </div>
                })}
            </Carousel>
            </div>
        </>

    );
}

export default ImgCorousel;