import React from "react";
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Home from './components/Pages/HomePage/Home';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import ImmunoClustDemo from "./components/Pages/ImmunoClustDemoPage/ImmunoClustDemo";
import LegalNotice from "./components/Pages/LegalNotice/LegalNotice";
import PrivacyPolice from "./components/Pages/PrivacyPolice/PrivacyPolice";
import About from "./components/Pages/About/About";
import Publications from "./components/Pages/Publications/Publications";
import CookieConsent from "react-cookie-consent";
import "./App.css"



function App() {
    return (
        <>
            <CookieConsent
                            location={"bottom"}
                            style={{
                                backgroundColor:"rgb(101,122,151)",
                                color:"white",
                            }}
                            expires={30}
                            buttonStyle={{ backgroundColor: "rgb(236,175,110)", color: "white", fontSize: "1rem" , borderRadius:"50px" , padding:"8px 20px" }}
            >
                We only use technically necessary cookies for the presentation and functionality of our website. These cannot be switched off by the user. This means that we comply with the current EU directive DSGVO 2022.
            </CookieConsent>
            <Router>
                <Navbar/>
                <Switch>
                    <Route path='/' exact component={Home}/>
                    <Route path='/immunoclust-demo' exact component={ImmunoClustDemo}/>
                    <Route path='/legal-notice' exact component={LegalNotice}/>
                    <Route path='/privacy' exact component={PrivacyPolice}/>
                    <Route path='/about' exact component={About}/>
                    <Route path='/publications' exact component={Publications}/>
                </Switch>
                <Footer/>
            </Router>
        </>
    );
}

export default App;
