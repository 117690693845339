import React from 'react';
import './Title.css'

function Title({title , img}) {
    return <>
        <div className={'container'} style={{backgroundImage:`url(${img})`, backgroundPosition:"center 30%", backgroundSize:"cover" , textAlign:"center" }}>
            <h1>{title}</h1>
        </div>
    </>
}

export default Title;