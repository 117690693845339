import React from 'react';
import './Form.css';
import CustomHorizontalSpace from "../Assistants/SpaceAssistant";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Colors} from "../../resources/Colors";

function FormSuccess() {
    return (
        <div className='form-content-right' style={{display:"flex" , flexDirection:'column' , alignItems:'center' , justifyContent:'center'}}>
            <FontAwesomeIcon icon={faMailBulk} size={'6x'} color={Colors.bioretisDark}/>
            <CustomHorizontalSpace space={50}/>
            <h1>Thank You!</h1>
            <CustomHorizontalSpace space={50}/>
            <h2 style={{marginRight:"10%" , marginLeft:"10%"}}>We have received your request.</h2>

        </div>
    );
}

export default FormSuccess;