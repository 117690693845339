export default function validateInfo(values) {
    let errors = {};

    if (!values.name.trim()) {
        errors.name = 'name is required';
    }
    // else if (!/^[A-Za-z]+/.test(values.name.trim())) {
    //   errors.name = 'Enter a valid name';
    // }

    if (!values.email) {
        errors.email = 'email is required';
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = 'Email address is invalid';
    }

    if (!values.message) {
        errors.message = 'message is required';
    }

    if (!values.privacy){
        errors.privacy = 'privacy is required'
    }

    return errors;
}