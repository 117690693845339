import React from "react";
import Title from "../../Title/Title";
import {Grid} from "@mui/material";
import './ImmunoClustDemoPage.css'
import "animate.css/animate.min.css";
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import fileUploadImg from "./images/fileUpload.png"
import batchPrefs from "./images/batchPrefs.png"
import preProcessTodo from "./images/preProcessToDo.png"
import preProcessDone from "./images/PreProcessDone.png"
import pcParams from "./images/primClustParams.png"
import pcRun from "./images/pcProcessRunning.png"
import pcDone from "./images/pcProcessDone.png"
import pcResult from "./images/pcResult.png"
import mcParams from "./images/mcParams.png"
import mcDone from "./images/mcDone.png"
import mcReullt from "./images/mcResult.png"
import mcResultBig from "./images/mcResultBig.png"
import anno from "./images/AnnoSimple.mp4"

import cluster from "../../../resources/images/mel-poole-ToI01Apo4Pk-unsplash.jpg.webp"

import ImageSectionRight from "./ImageSectionRight";
import {AnimationOnScroll} from "react-animation-on-scroll";
import {Button} from "../../Buttons/Button";
import paper from "../../../resources/files/cyto.a.22626.pdf"


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


function ImmunoClustDemo() {

    let downloadFile = () => {
        window.open(
            paper,
            '_blank' // <- This is what makes it open in a new window.
        );
    }


    return <>

        <Title title={'immunoClust Demonstration'} img={cluster}/>
        <CustomHorizontalSpace space={100}/>
        <Grid container className={'container--intro'} style={{paddingLeft: "10%", paddingRight: "10%"}}>
            <h1 style={{marginBottom: "50px"}}>immunoClust - an Automated Pipeline for Population Detection in Flow
                Cytometry</h1>

            <p>Multiparametric fluorescence and mass cytometry offers new perspectives to disclose and to monitor
                the
                high diversity of cell populations in the peripheral blood for biomarker research. While high-end
                cytometric devices are currently available to detect theoretically up to 120 individual parameters
                at
                the single cell level, software tools are needed to analyze these complex datasets automatically in
                acceptable time and without operator bias or knowledge. </p>
            <br/>
            <p>We developed an automated analysis pipeline, immunoClust, for uncompensated fluorescence and mass
                cytometry data, which consists of two parts:</p>
            <br/>

            <ul style={{marginTop:"10px", marginBottom:"10px"}}>
                <li>
                    First, cell events of each sample are grouped into individual clusters.
                </li>
                <li>
                    Subsequently, a classification algorithm assorts these cell event clusters into populations
                    comparable between different samples.
                </li>
            </ul>
            <br/>
            <p> The clustering of cell events is designed for datasets with large event counts in high dimensions as
                a
                global unsupervised method, sensitive to identify rare cell types even when next to large
                populations.
                Both parts use model-based clustering with an iterative expectation maximization algorithm and the
                integrated classification likelihood to obtain the clusters. </p>
            <br/>


        </Grid>
        <CustomHorizontalSpace space={100}/>
        <Grid container className={'container--intro'} style={{paddingLeft: "10%", paddingRight: "10%"}}>
            <Button buttonSize={"btn--large"} buttonStyle={"btn--outline--dark"} onClick={downloadFile} target="_blank">Download
                Paper</Button>
        </Grid>


        <CustomHorizontalSpace space={100}/>


        <Grid container justifyContent={"center"} alignItems={"center"}>
            <div className={"container--column--align-left"}>
                <div className={"line"}/>
                <h3>Analysis Overview</h3>
                <CustomHorizontalSpace space={20}/>
            </div>

            <div className={"container-column--align--left--width50"}>
                <p><strong>Cell-Event Clustering:</strong> detecting cell-groups of common parameter distribution in
                    each FCS-sample.</p>
                <br/>
                <p><strong>Meta-Clustering:</strong> detecting common meta-populations of found cell-clusters across
                    multiple samples.</p>
                <br/>
                <p><strong>Meta-Cluster Annotation:</strong> build up a hierarchical population structure.</p>
                <br/>
            </div>
        </Grid>


        <CustomHorizontalSpace space={200}/>


        <ImageSectionRight
            data={[{url: fileUploadImg, legend: "Upload File"}, {url: batchPrefs, legend: "Batch Preferences"}]}
            title={"File Upload"}
            txt={["Select multible FCS files to upload and define global Batch preferences."]}
            animation={"animate__fadeInRightBig"}/>
        <CustomHorizontalSpace space={200}/>
        <ImageSectionRight data={[{url: preProcessTodo, legend: "Pre Process start"}, {
            url: preProcessDone,
            legend: "Pre Process done"
        }]} title={"Pre Process"}
                           txt={["The Pre Process starts automatically after the upload.", "All parameters are read from the respective FCS files.", "After finishing the Pre Process, the selection for the cluster parameters is active."]}
                           animation={"animate__fadeInRightBig"}/>
        <CustomHorizontalSpace space={200}/>
        <ImageSectionRight data={[{url: pcParams, legend: "Primary Clust Parameter"}]} title={"Primay Clust Parameter"}
                           txt={["Select parameter for the Primary Clust Process.", "After Selection the Primary Clust process starts automatically."]}
                           animation={"animate__fadeInRightBig"}/>
        <CustomHorizontalSpace space={200}/>
        <ImageSectionRight data={[{url: pcRun, legend: "Primary Clust Process running"}, {
            url: pcDone,
            legend: "Primary Clust Process done"
        }, {url: pcResult, legend: "Primary Clust Process result"}]} title={"Primary Clust Process"}
                           txt={["The Primary Clust Process is detecting cell-groups of common parameter distribution in each FCS-sample.", "After process finish, the result of each sample is displayed."]}
                           animation={"animate__fadeInRightBig"}/>
        <CustomHorizontalSpace space={200}/>
        <ImageSectionRight data={[{url: mcParams, legend: "Meta Clust Parameter"}]} title={"Meta Clust Parameter"}
                           txt={["Select parameter for the Meta Clust Process.", "After Selection the Meta Clust process starts automatically."]}
                           animation={"animate__fadeInRightBig"}/>
        <CustomHorizontalSpace space={200}/>
        <ImageSectionRight data={[{url: mcDone, legend: "Meta Clust Process done"}, {
            url: mcReullt,
            legend: "Meta Clust Process result"
        }, {url: mcResultBig, legend: "Meta Clust Process result big"}]} title={"Meta Clust Process"}
                           txt={["The Meta Clust Process is detecting common meta-populations of found cell-clusters across multiple samples.", "After process finish, the result is displayed."]}
                           animation={"animate__fadeInRightBig"}/>
        <CustomHorizontalSpace space={200}/>

        <Grid container>

            <Grid item xs={12} md={6} className={"txt-container"}
                  style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <AnimationOnScroll animateIn={"animate__fadeInLeftBig"}>
                    <div className={"txt-container-column"} style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                        marginBottom: "5%"
                    }}>
                        <div className={"line"}/>
                        <h3>Annotation</h3>
                        <CustomHorizontalSpace space={20}/>
                        <p>The Annotation is build up a hierarchical population structure.</p>
                    </div>
                </AnimationOnScroll>
            </Grid>
            <Grid item xs={12} md={6} className={"img-container"}>
                <video width="100%" height="100%" controls
                       style={{position: "inherit", zIndex: "2", border: "1px solid rgb(236,175,110)"}}>
                    <source src={anno} type={"video/mp4"}/>
                </video>
            </Grid>
        </Grid>

        <CustomHorizontalSpace space={200}/>


    </>


}

export default ImmunoClustDemo