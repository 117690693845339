import React, {useRef} from 'react';
import useForm from "./useForm";
import validate from './validateInfo';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {HashLink as Link} from "react-router-hash-link";
import CustomHorizontalSpace from "../Assistants/SpaceAssistant";

function FormSignup({submitForm}) {

    const form = useRef();

    const { handleChange, handleSubmit, values, errors } = useForm(
        submitForm,
        validate,
        form,
    );

    return (

                <div className='form-content-right'>
                    <form ref={form} onSubmit={handleSubmit} className='form' noValidate  >
                        <div className='form-inputs'>
                            <label className='form-label'>Name</label>
                            <CustomHorizontalSpace space={5}/>
                            <input
                                className='form-input'
                                type='text'
                                name='name'
                                placeholder='Enter your name'
                                value={values.name}
                                onChange={handleChange}
                            />
                            {errors.name && <p>{errors.name}</p>}
                        </div>
                        <div className='form-inputs'>
                            <label className='form-label'>Email</label>
                            <CustomHorizontalSpace space={5}/>
                            <input
                                className='form-input'
                                type='email'
                                name='email'
                                placeholder='Enter your email'
                                value={values.email}
                                onChange={handleChange}
                            />
                            {errors.email && <p>{errors.email}</p>}
                        </div>
                        <div className='form-inputs'>
                            <label> Message</label>
                            <CustomHorizontalSpace space={5}/>
                            <textarea
                                className='form-input-message'
                                rows='15'
                                name='message'
                                placeholder='Enter your message'
                                value={values.message}
                                onChange={handleChange}
                            />
                            {errors.message && <p>{errors.message}</p>}
                        </div>
                        <div className='form-inputs' >
                            <div style={{display:'flex' , direction:'row'}}>
                                <div className={'box1'}>
                                    <input
                                        className='form-input checkbox'
                                        type='checkbox'
                                        name='privacy'
                                        value={values.privacy}
                                        onChange={handleChange}
                                    />
                                </div>
                                <label className='box2'><span>I accept the </span>
                                    <Link className={'custom--link'} to={'/privacy'}>privacy policy.</Link></label>
                            </div>
                            {errors.privacy && <p>{errors.privacy}</p>}
                        </div>
                        <button style={{minWidth:'150px'}} className='form-input-btn btn--form btn--large' type='submit'>
                            <FontAwesomeIcon icon={faPaperPlane} style={{marginRight: '15px'}}/> Sent
                        </button>
                    </form>
                </div>

    );
}

export default FormSignup;