import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './resources/fonts/Space_Mono/SpaceMono-Regular.ttf'
import './resources/fonts/PT_Sans/PTSans-Regular.ttf'

ReactDOM.render(<App />,
  document.getElementById('root')
);


