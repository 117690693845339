import React from 'react';
import Title from "../../Title/Title";
import AboutHero from "./AboutHero";
import {Grid} from "@mui/material";
import './About.css'
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import OurTeam from "./OurTeam";
import AltContactForm from "../../Contact/AltContactForm";
import berlin from '../../../resources/images/florian-wehde-1uWanmgkd5g-unsplash-2.jpg.webp'
import {AnimationOnScroll} from "react-animation-on-scroll";

function About() {
    return <>
        <Title title={'About Us'} img={berlin}/>
        <CustomHorizontalSpace space={100}/>
        <Grid container className={'grid--item'}>
            <AboutHero/>
        </Grid>
        <CustomHorizontalSpace space={200}/>
        <Grid container direction={'column'} style={{justifyContent:"center", alignItems:"center"}}>
            <AnimationOnScroll animateIn={"animate__fadeInLeftBig"}>
                <div className={"line"}/>
                <h1>Our Team</h1>
            </AnimationOnScroll>
            <CustomHorizontalSpace space={100}/>
            <div style={{width:"80%"}}>
                <OurTeam/>
            </div>
        </Grid>
        <CustomHorizontalSpace space={200}/>
        <Grid container className={'grid--item'} direction={'column'}>
            <iframe className={'map'}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2427.4349944935125!2d13.37224861628291!3d52.52556344356863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a85195777a1771%3A0x53f2f9ac032e1985!2sCharit%C3%A9pl.%201%2C%2010117%20Berlin!5e0!3m2!1sde!2sde!4v1635327873943!5m2!1sde!2sde"
                 style={{border:'0' , width: '100%' , height:'40vh'}} title={'map'} loading="lazy"/>
        </Grid>
        <CustomHorizontalSpace space={200}/>
        <div className={"line"} style={{marginLeft:"10%"}}/>
        <h1 style={{textAlign:'left' , marginLeft:'10%' , marginRight:'10%'}}>
            Get started with us today!
        </h1>
        <AltContactForm/>
        <CustomHorizontalSpace space={200}/>
    </>
}

export default About;