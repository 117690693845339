import React from 'react';
import './Footer.css';
import {HashLink as Link} from "react-router-hash-link";
import logo from "../../resources/images/bioretis-icon.png";
import ButtonMailTo from "../Buttons/ButtonMailTo";
import ButtonCallTo from "../Buttons/ButtonCallTo";

function Footer() {
    return (
        <div className='footer-container'>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>Contact Us</h2>
                        <ButtonMailTo mailto={'mailto:mail@bioretis.com'} label={'mail@bioretis.com'} />
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div className='footer-link-items'>
                        <h2>Legal</h2>
                        <Link to='/legal-notice'>Legal Notice</Link>
                        <Link to='/privacy'>Privacy</Link>
                    </div>

                </div>
            </div>
            <section className='social-media'>
                <div className='social-media-wrap'>
                    <div className='footer-logo'>
                        <Link to='/#home' className='social-logo'>
                                <img src={logo} alt="Logo" width={"50px"}/>
                                <h5>BioRetis</h5>
                        </Link>
                    </div>
                    <small className='website-rights'>© Copyright BioRetis GmbH 2021-2023</small>
                </div>
            </section>
        </div>
    );
}

export default Footer;