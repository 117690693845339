import React from 'react';
import './Button.css';


const STYLES = ['btn--primary', 'btn--outline','btn--outline--dark', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
                           children,
                           type,
                           onClick,
                           buttonStyle,
                           buttonSize,
                       }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    return (
            <button
                onClick={onClick}
                className={`btn ${checkButtonStyle} ${checkButtonSize}`}
                type={type}
            >
                {children}
            </button>
    );
};
