import React from 'react';
import './Employee.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {Colors} from "../../resources/Colors";
import {Grid} from "@mui/material";
import CustomHorizontalSpace from "../Assistants/SpaceAssistant";


function Employee({name, task, degree, empl, mail, maildummy}) {
    return <>
        <Grid container direction={'column'} alignItems={'center'} style={{marginBottom: '2rem'}}>
            <div className={empl}/>
            <br/>
            <div style={{textAlign: "left"}}>
                <h3>{name}</h3>
                <div style={{textAlign: "left"}}>
                    <div className={"line"}></div>
                </div>
                <br/>
                <p>{degree}</p>
                <CustomHorizontalSpace space={10}/>
                <strong>{task}</strong>
                <CustomHorizontalSpace space={10}/>
                <p style={{color: Colors.bioretisBlue}}><FontAwesomeIcon icon={faEnvelope} style={{
                    marginRight: '5px',
                    color: Colors.bioretisBlue
                }}/> {maildummy}</p>
            </div>


        </Grid>

    </>
}

export default Employee;