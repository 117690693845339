import React from 'react';
import {Grid} from "@mui/material";
import Employee from "../../Employee/Employee";
import {AnimationOnScroll} from "react-animation-on-scroll";

function OurTeam() {
    return <>
        <AnimationOnScroll animateIn={"animate__fadeInLeftBig"} delay={500}>
        <Grid container style={{display:"flex",justifyContent:"space-between", alignItems:"center"}}>
            <Grid item xs={12} md={6} lg={3}>
                <Employee  name={'Till Sörensen'} degree={'DIPLOMA IN MATHEMATICS'} empl={'img--till'} task={'Partner | Research & Development'}  maildummy={'till.soerensen[at]bioretis.com'}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Employee name={'Sascha Johannes'} degree={'M. SC. BIOINFORMATICS'} empl={'img--sascha'} task={'Partner | Head of Development'}  maildummy={'sascha.johannes[at]bioretis.com'}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Employee name={'Bettina Johannes'} degree={'B. SC. MATHEMATICS'} empl={'img--bettina'} task={'Research & Development'}  maildummy={'bettina.johannes[at]bioretis.com'}/>
            </Grid>
        </Grid>
        <Grid container  style={{display:"flex",justifyContent:"space-between", alignItems:"center",marginTop:'100px'}}>
            <Grid item xs={12} md={6} lg={3}  className={'grid--item'}>
                <Employee name={'Marc Bonin-Andresen'} degree={'M. SC. BIOINFORMATICS'} empl={'img--marc'} task={'Partner | Management'}  maildummy={'marc.bonin-andresen[at]bioretis.com'}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3}  className={'grid--item'}>
                <Employee name={'Thomas Häupl'} degree={'PD DR. MED. HABIL.'} empl={'img--thomas'} task={'Partner | Research & Development'}  maildummy={'thomas.haeupl[at]bioretis.com'}/>
            </Grid>
            <Grid item xs={12} md={6} lg={3} className={'grid--item'}>
                <Employee name={'Rainer Seubert'} empl={'img--rainer'} task={'Partner | CEO'}  maildummy={'rainer.seubert[at]bioretis.com'}/>
            </Grid>
        </Grid>
        </AnimationOnScroll>
    </>
}

export default OurTeam;