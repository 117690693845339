import React from 'react';
import '../../../App.css';
import HeroSection from '../../Heros/HeroSection';
import OverviewImmunoClustProps from "./OverviewImmunoClustProps";
import HeroImmunoClust from "./HeroImmunoClust";
import GoToDemoSection from "./GoToDemoSection";
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import AltContactForm from "../../Contact/AltContactForm";
import {Hidden} from "@mui/material";

function Home() {
    return (
        <>
            <HeroSection />
            <CustomHorizontalSpace space={100}/>
            <HeroImmunoClust/>
            <CustomHorizontalSpace space={200}/>
            <GoToDemoSection/>
            <CustomHorizontalSpace space={150}/>
            <Hidden mdDown>
                <OverviewImmunoClustProps />
                <CustomHorizontalSpace space={150}/>
            </Hidden>

            <div className={"line"} style={{marginLeft:"10%"}}/>
            <h1 style={{textAlign:'left' , marginLeft:'10%' , marginRight:'10%'}}>
                Get started with us today!
            </h1>
            <AltContactForm/>
            <CustomHorizontalSpace space={150}/>

        </>
    );
}

export default Home;