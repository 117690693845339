import React from 'react';
import Title from "../../Title/Title";
import "./Publications.css"
import {Grid, Hidden, Icon} from "@mui/material";

import file from "../../../resources/files/prerequisites_to_define_and_to_validate_therapy_predicitive_blood_biomarkers.pdf"
import magazines from "../../../resources/images/susan-q-yin-2JIvboGLeho-unsplash.jpg.webp"
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import {Colors} from "../../../resources/Colors";


function Publications(props) {

    let[showNav , setShowNav] = React.useState(false)


    return (
        <div>

            { showNav ? <div style={{backgroundColor:Colors.bioretisContrast , position:"fixed" , zIndex:"1000", width:"100%",height:"100vh"}}>
                <Icon style={{position:"absolute" , marginLeft:"3%" , top:"5%"}}>
                    <CloseIcon sx={{ color: "white" , fontSize:"2rem"}} onClick={() =>setShowNav(false)} />
                </Icon>
                <nav className={"bs-docs-sidebar"}>
                    <ul id={"sidebar"} className={"nav nav-stacked affix"}>
                        <li className={"active"} style={{textAlign:"center"}}>
                            <a href={"#publications"}>
                                <span style={{ fontFamily: 'space_monoregular'}}>Publications</span>
                            </a>
                            <ul className={"nav nav-stacked"}>
                                <li><a href={"#PublikationenSub2021"} title={"Publikationen 2021"} onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2021</span>
                                </a></li>
                                <li><a href={"#PublikationenSub2020"} title={"Publikationen 2020"} onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2020</span>
                                </a></li>
                                <li><a href="#PublikationenSub2019" title="Publikationen 2019" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2019</span>
                                </a></li>
                                <li><a href="#PublikationenSub2018" title="Publikationen 2018" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2018</span>
                                </a></li>
                                <li><a href="#PublikationenSub2017" title="Publikationen 2017" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2017</span>
                                </a></li>
                                <li><a href="#PublikationenSub2016" title="Publikationen 2016" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2016</span>
                                </a></li>
                                <li><a href="#PublikationenSub2015" title="Publikationen 2015" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2015</span>
                                </a></li>
                                <li><a href="#PublikationenSub2014" title="Publikationen 2014" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2014</span>
                                </a></li>
                                <li><a href="#PublikationenSub2013" title="Publikationen 2013" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2013</span>
                                </a></li>
                                <li><a href="#PublikationenSub2012" title="Publikationen 2012" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2012</span>
                                </a></li>
                                <li><a href="#PublikationenSub2011" title="Publikationen 2011" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}} >2011</span>
                                </a></li>
                                <li><a href="#PublikationenSub2010" title="Publikationen 2010" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}} >2010</span>
                                </a></li>
                                <li><a href="#PublikationenSub2009" title="Publikationen 2009" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2009</span>
                                </a></li>
                                <li><a href="#PublikationenSub2008" title="Publikationen 2008" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2008</span>
                                </a></li>
                                <li><a href="#PublikationenSub2007" title="Publikationen 2007" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2007</span>
                                </a></li>
                                <li><a href="#PublikationenSub2006" title="Publikationen 2006" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>2006</span>
                                </a></li>
                                <li><a href="#PublikationenSub2005" title="Publikationen 2005" onClick={() => setShowNav(false)}>
                                    <span style={{color:"white"}}>before</span>
                                </a></li>
                            </ul>

                        </li>

                    </ul>

                </nav>
            </div> : null}

            <Title title={'Publications'} img={magazines}/>
            <Grid container className={"cont"}>
                <Grid item md={4} className={"left-nav"}>
                    <nav className={"bs-docs-sidebar"}>
                        <ul id={"sidebar"} className={"nav nav-stacked affix"}>
                            <li className={"active"}>
                                <a href={"#publications"}>
                                    <span style={{ fontFamily: 'space_monoregular'}}>Publications</span>
                                </a>
                                <ul className={"nav nav-stacked"} >
                                    <li><a href={"#PublikationenSub2021"} title={"Publikationen 2021"} >
                                        <span>2021</span>
                                    </a></li>
                                    <li><a href={"#PublikationenSub2020"} title={"Publikationen 2020"}>
                                        <span>2020</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2019" title="Publikationen 2019">
                                        <span >2019</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2018" title="Publikationen 2018">
                                        <span >2018</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2017" title="Publikationen 2017">
                                        <span >2017</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2016" title="Publikationen 2016">
                                        <span >2016</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2015" title="Publikationen 2015">
                                        <span >2015</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2014" title="Publikationen 2014">
                                        <span >2014</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2013" title="Publikationen 2013">
                                        <span >2013</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2012" title="Publikationen 2012">
                                        <span >2012</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2011" title="Publikationen 2011">
                                        <span >2011</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2010" title="Publikationen 2010">
                                        <span >2010</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2009" title="Publikationen 2009">
                                        <span >2009</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2008" title="Publikationen 2008">
                                        <span >2008</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2007" title="Publikationen 2007">
                                        <span >2007</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2006" title="Publikationen 2006">
                                        <span >2006</span>
                                    </a></li>
                                    <li><a href="#PublikationenSub2005" title="Publikationen 2005">
                                        <span >before</span>
                                    </a></li>
                                </ul>

                            </li>

                        </ul>

                    </nav>
                </Grid>
                <Grid item xs={ 12 } md={8} className={"publications"}>
                    <Hidden mdUp>
                        <Icon style={{position:"absolute" , marginLeft:"3%"}}>
                            <ListIcon sx={{ color: Colors.bioretisBlue , fontSize:"2rem"}} onClick={() => setShowNav(true)}/>
                        </Icon>
                    </Hidden>
                        <section id="Publikationen" className="group">
                            <div id="PublikationenSub2021" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2021</span>
                                        <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Christoph H Emmerich, Lorena Martinez Gamboa, Martine C J Hofmann, <b>Marc Bonin-Andresen</b>, Olga Arbach,<b>Pascal Schendel</b>, Björn Gerlach, Katja Hempel, Anton Bespalov, Ulrich Dirnagl, Michael J Parnham. (2021) <a className="pubmedlink"
                                                           href="https://pubmed.ncbi.nlm.nih.gov/33199880/"
                                                           target="_blank"  rel="noreferrer">PMID: 33199880</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2020" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2020</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Scherer HU, <b>H&auml;upl T</b>, Burmester GR.
                                    The etiology of rheumatoid arthritis.
                                    J Autoimmun. (2020) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/31980337"
                                                           target="_blank"  rel="noreferrer">PMID: 31980337</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2019" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2019</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Glimm AM, Sprenger LI, Haugen IK, Mansmann U, Hermann S, <b>H&auml;upl T</b>, Hoff P,
                                    Burmester GR, Backhaus M, Le L, Ohrndorf S.
                                    Fluorescence optical imaging for treatment monitoring in patients with early and active
                                    rheumatoid arthritis in a 1-year follow-up period.
                                    Arthritis Res Ther. (2019) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/31533820"
                                                                  target="_blank"  rel="noreferrer">PMID: 31533820</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Toldi G, Batel P, Baráth S, Szerémy P, Apjok A, Filkor K, Szántó S, Szűcs G, Szamosi
                                    S, <b>H&auml;upl T</b>, Gr&uuml;tzkau A, Szekanecz Z.
                                    Peripheral Lymphocyte Multidrug Resistance Activity as a Predictive Tool of Biological
                                    Therapeutic Response in Rheumatoid Arthritis.
                                    J Rheumatol. (2019) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/30709954"
                                                           target="_blank"  rel="noreferrer">PMID: 30709954</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2018" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2018</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Schulte-Wrede U, <b>S&ouml;rensen T</b>, Gr&uuml;n JR, <b>H&auml;upl T</b>, Hirseland H,
                                    Steinbrich-Z&ouml;llner M, Wu P, Radbruch A, Poddubnyy D, Sieper J, Syrbe U,
                                    Gr&uuml;tzkau A.
                                    An explorative study on deep profiling of peripheral leukocytes to identify predictors
                                    for responsiveness to anti-tumour necrosis factor alpha therapies in ankylosing
                                    spondylitis: natural killer cells in focus.
                                    Arthritis Res Ther. (2018) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/30157966"
                                                                  target="_blank"  rel="noreferrer">PMID: 30157966</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ammari M, Presumey J, Ponsolles C, Roussignol G, Roubert C, Escriou V, Toupet K,
                                    Mausset-Bonnefont AL, Cren M, Robin M, Georgel P, Nehmar R, Taams L, Gr&uuml;n JR,
                                    Gr&uuml;tzkau A, <b>H&auml;upl T</b>, Pers YM, Jorgensen C, Duroux-Richard I, Courties
                                    G, Apparailly F.
                                    Delivery of miR-146a to Ly6Chigh Monocytes Inhibits Pathogenic Bone Erosion in
                                    Inflammatory Arthritis.
                                    Theranostics. (2018) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/30613275"
                                                            target="_blank"  rel="noreferrer">PMID: 30613275</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Skapenko A, Hoppe B, Skriner K, Burkhardt H, Poddubnyy D, Ohrndorf
                                    S, Sewerin P, Mansmann U, Stuhlm&uuml;ller B, Schulze-Koops H, Burmester GR.
                                    Biomarkers and imaging for diagnosis and stratification of rheumatoid arthritis and
                                    spondylarthritis in the BMBF consortium ArthroMark.
                                    Z Rheumatol. (2018) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/29691690"
                                                           target="_blank"  rel="noreferrer">PMID: 29691690</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Popadić D, He&szlig;elbach K, Richter-Brockmann S, Kim GJ, Flemming S, Schmidt-Heck
                                    W, <b>H&auml;upl T</b>, <b>Bonin-Andresen M</b> Dornhof R, Achten C, G&uuml;nther S,
                                    Humar M, Merfort I.
                                    Gene expression profiling of human bronchial epithelial cells exposed to fine
                                    particulate matter (PM2.5) from biomass combustion.
                                    Toxicol Appl Pharmacol. (2018) <a className="pubmedlink"
                                                                      href="http://www.ncbi.nlm.nih.gov/pubmed/29596927"
                                                                      target="_blank"  rel="noreferrer">PMID: 29596927</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Schwedler C, <b>H&auml;upl T</b>, Kalus U, Blanchard V, Burmester GR, Poddubnyy D, Hoppe
                                    B.
                                    Hypogalactosylation of immunoglobulin G in rheumatoid arthritis: relationship to
                                    HLA-DRB1 shared epitope, anticitrullinated protein antibodies, rheumatoid factor, and
                                    correlation with inflammatory activity.
                                    Arthritis Res Ther. (2018) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/29540200"
                                                                  target="_blank"  rel="noreferrer">PMID: 29540200</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Bonin-Andresen M</b>, <b>Smiljanovic B</b>, Stuhlm&uuml;ller B, <b>S&ouml;rensen
                                    T</b>, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    Relevance of big data for molecular diagnostics.
                                    Z Rheumatol. (2018) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/29520680"
                                                           target="_blank"  rel="noreferrer">PMID: 29520680</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Burmester GR, <b>H&auml;upl T</b>.
                                    Big data-also relevant in rheumatology?
                                    Z Rheumatol. (2018) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/29619762"
                                                           target="_blank"  rel="noreferrer">PMID: 29619762</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2017" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2017</span>
                                    <div className={"line"}></div></h2>
                                </div>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Radzikowska A, Kuca-Warnawin E, Kurowska W, Gr&uuml;n JR,
                                    Stuhlm&uuml;ller B, <b>Bonin-Andresen M</b>, Schulte-Wrede U, <b>S&ouml;rensen T</b>,
                                    Kyogoku C, Bruns A, Hermann S, Ohrndorf S, Aupperle K, Backhaus M, Burmester GR,
                                    Radbruch A, Gr&uuml;tzkau A, Maslinski W, <b>H&auml;upl T</b>.
                                    Monocyte alterations in rheumatoid arthritis are dominated by preterm release from bone
                                    marrow and prominent triggering in the joint.
                                    Ann Rheum Dis. (2017) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/29191820"
                                                             target="_blank"  rel="noreferrer">PMID: 29191820</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, <b>Bonin-Andresen M</b>, Stuhlm&uuml;ller B, <b>S&ouml;rensen
                                    T</b>, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    Comment on "Single cell RNA-seq reveals new types of human blood dendritic cells,
                                    monocytes and progenitors."
                                    (2017) <a className="pubmedlink" href="publikationen_science_comment2017.php">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    He&szlig;elbach K, Kim GJ, Flemming S, <b>H&auml;upl T</b>, <b>Bonin-Andresen M</b>,
                                    Dornhof R, G&uuml;nther S, Merfort I, Humar M.
                                    Disease relevant modifications of the methylome and transcriptome by particulate matter
                                    (PM2.5) from biomass combustion.
                                    Epigenetics (2017) <a className="pubmedlink"
                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/28742980"
                                                          target="_blank"  rel="noreferrer">PMID: 28742980</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Krenn V, Perino G, R&uuml;ther W, Krenn VT, Huber M, H&uuml;gle T, Najm A, M&uuml;ller
                                    S, Boettner F, Pessler F, Waldstein W, Kriegsmann J, Casadonte R, <b>H&auml;upl T</b>,
                                    Wienert S, Krukemeyer MG, Sesselmann S, Sunitsch S, Tikhilov R, Morawietz L.
                                    15 years of the histopathological synovitis score, further development and review: A
                                    diagnostic score for rheumatology and orthopaedics.
                                    Pathol Res Pract. (2017) <a className="pubmedlink"
                                                                href="http://www.ncbi.nlm.nih.gov/pubmed/28687159"
                                                                target="_blank"  rel="noreferrer">PMID: 28687159</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Stich S, Loch A, Park SJ, <b>H&auml;upl T</b>, Ringe J, Sittinger M.
                                    Characterization of single cell derived cultures of periosteal progenitor cells to
                                    ensure the cell quality for clinical application.
                                    PLoS One (2017) <a className="pubmedlink"
                                                       href="http://www.ncbi.nlm.nih.gov/pubmed/28562645" target="_blank"  rel="noreferrer">PMID:
                                    28562645</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Krenn V, Perino G, R&uuml;ther W, Krenn VT, Huber M, H&uuml;gle T, Najm A, M&uuml;ller
                                    S, Boettner F, Pessler F, Waldstein W, Kriegsmann J, <b>H&auml;upl T</b>, Wienert S,
                                    Krukemeyer MG, Sesselmann S, Tikhilov R, Morawietz L.
                                    Fifteen years of the histopathological synovitis score: Review and further developments
                                    of a diagnostic score.
                                    Z Rheumatol. (2017) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/28470440"
                                                           target="_blank"  rel="noreferrer">PMID: 28470440</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Latsoudis H, Mashreghi MF, Gr&uuml;n JR, Chang HD, Stuhlm&uuml;ller B, Repa A,
                                    Gergiannaki I, Kabouraki E, Vlachos GS, <b>H&auml;upl T</b>, Radbruch A, Sidiropoulos P,
                                    Doukoumetzidis K, Kardassis D, Niewold TB, Boumpas DT, Goulielmos GN.
                                    Differential Expression of miR-4520a Associated With Pyrin Mutations in Familial
                                    Mediterranean Fever (FMF).
                                    J Cell Physiol. (2017) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/27636101"
                                                              target="_blank"  rel="noreferrer">PMID: 27636101</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Strau&szlig; R, Rose T, Flint SM, Klotsche J, <b>H&auml;upl T</b>, Peck-Radosavljevic M,
                                    Yoshida T, Kyogoku C, Flechsig A, Becker AM, Dao KH, Radbruch A, Burmester GR, Lyons PA,
                                    Davis LS, Hiepe F, Gr&uuml;tzkau A, Biesen R.
                                    Type I interferon as a biomarker in autoimmunity and viral infection: a leukocyte
                                    subset-specific analysis unveils hidden diagnostic options.
                                    J Mol Med. (2017) <a className="pubmedlink"
                                                         href="http://www.ncbi.nlm.nih.gov/pubmed/28357476" target="_blank"  rel="noreferrer">PMID:
                                    28357476</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Radzikowska A, Kuca-Warnawin E, Kurowska W, <b>S&ouml;rensen T</b>,
                                    Stuhlm&uuml;ller B, <b>Bonin-Andresen M</b>, Gr&uuml;n JR, Burmester GR, Radbruch A,
                                    Gr&uuml;tzkau A, Maslinski W, <b>H&auml;upl T</b>.
                                    Increased Turnover of Monocytes in Patients with Rheumatoid Arthritis Identified by
                                    Transcriptome and Cytometric Profiling.
                                    Ann Rheum Dis. (2017) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/76/Suppl_1/A55.1"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2016" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2016</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Duroux-Richard I, Roubert C, Ammari M, Pr&eacute;sumey J, Gr&uuml;n JR, <b>H&auml;upl
                                    T</b>, Gr&uuml;tzkau A, Lecellier CH, Boitez V, Codogno P, Escoubet J, Pers YM,
                                    Jorgensen C, Apparailly F.
                                    miR-125b controls monocyte adaptation to inflammation through mitochondrial metabolism
                                    and dynamics.
                                    Blood (2016) <a className="pubmedlink"
                                                    href="http://www.ncbi.nlm.nih.gov/pubmed/27702798" target="_blank"  rel="noreferrer">PMID:
                                    27702798</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Latsoudis H, Mashreghi MF, Gr&uuml;n JR, Chang HD, Stuhlm&uuml;ller B, Repa A,
                                    Gergiannaki I, Kabouraki E, <b>H&auml;upl T</b>, Radbruch A, Sidiropoulos P, Kardassis
                                    D, Boumpas DT, Goulielmos GN.
                                    Differential Expression of miR-4520a Associated with Pyrin Mutations Suggesting a Role
                                    of Autophagy in Familial Mediterranean Fever (FMF).
                                    J Cell Physiol. (2016) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/27636101"
                                                              target="_blank"  rel="noreferrer">PMID: 27636101</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Stuhlm&uuml;ller B, Mans K, Tandon N, <b>Bonin-Andresen M</b>, <b>Smiljanovic
                                    B</b>, <b>S&ouml;rensen T</b>, <b>Schendel P</b>, Martus P, Listing J, Detert J,
                                    Backhaus M, Neumann T, Winchester RJ, Burmester GR, <b>H&auml;upl T</b>.
                                    Genomic stratification by expression of HLA-DRB4 alleles identifies differential innate
                                    and adaptive immune transcriptional patterns - A strategy to detect predictors of
                                    methotrexate response in early rheumatoid arthritis.
                                    Clin Immunol. (2016) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/27570220"
                                                            target="_blank"  rel="noreferrer">PMID: 27570220</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Strehl C, Schellmann S, Maurizi L, Hofmann-Amtenbrink M, <b>H&auml;upl T</b>, Hofmann H,
                                    Buttgereit F, Gaber T.
                                    Effects of PVA-coated nanoparticles on human T helper cell activity.
                                    Toxicol Lett. (2016) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/26774940"
                                                            target="_blank"  rel="noreferrer">PMID: 26774940</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Stuhlm&uuml;ller B, <b>S&ouml;rensen T</b>, <b>Bonin-Andresen
                                    M</b>, <b>Pade S</b>, Backhaus M, Maslinski W, Burmester GR, Radbruch A, Gr&uuml;tzkau
                                    A, <b>H&auml;upl T</b>.
                                    Tissue- and Cell-Specific Transcriptomes Indicate Systemic Nature of RA and Revealed
                                    Combinations of Protein Biomarkers Relevant for Disease Characterisation in Serum.
                                    Ann Rheum Dis. (2016) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/75/Suppl_1/A49.3"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>S&ouml;rensen T</b>, Schulte-Wrede U, Hermann S, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    immunoClust based analysis of cytometric profiles reveals immunophenotypic changes in
                                    synovial fluid compared to peripheral blood cells in rheumatoid arthritis.
                                    Ann Rheum Dis. (2016) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/75/Suppl_1/A51.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2015" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2015</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Stuhlm&uuml;ller B, Skriner K, <b>H&auml;upl T</b>.
                                    Biomarkers for prognosis of response to anti-TNF therapy of rheumatoid arthritis: Where
                                    do we stand?
                                    Z Rheumatol. (2015) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/26347122"
                                                           target="_blank"  rel="noreferrer">PMID: 26347122</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Strehl C, Gaber T, Maurizi L, Hahne M, Rauch R, Hoff P, <b>H&auml;upl T</b>,
                                    Hofmann-Amtenbrink M, Poole AR, Hofmann H, Buttgereit F.
                                    Effects of PVA coated nanoparticles on human immune cells.
                                    Int J Nanomedicine. (2015) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/26056442"
                                                                  target="_blank"  rel="noreferrer">PMID: 26056442</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>S&ouml;rensen T</b>, Baumgart S, Durek P, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    immunoClust-An automated analysis pipeline for the identification of immunophenotypic
                                    signatures in high-dimensional cytometric datasets.
                                    Cytometry A. (2015) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/25850678"
                                                           target="_blank"  rel="noreferrer">PMID: 25850678</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Stuhlm&uuml;ller B, <b>Bonin-Andresen M</b>, <b>Pade S</b>,
                                    Backhaus B, Burmester GR, Radbruch A, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    The synovial tissue transcriptome reveals combinations of protein biomarkers for
                                    unambiguous identification of RA patients from synovial fluid and for quantification of
                                    disease activity in serum.
                                    Ann Rheum Dis. (2015) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/74/Suppl_1/A71.1"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2014" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2014</span>
                                    <div className={"line"}></div></h2>
                                </div>
                                <p className="justify">
                                    Haftmann C, Stittrich AB, Zimmermann J, Fang Z, Hradilkova K, Bardua M, Westendorf K,
                                    Heinz GA, Riedel R, Siede J, Lehmann K, Weinberger EE, Zimmel D, Lauer U, <b>H&auml;upl
                                    T</b>, Sieper J, Backhaus M, Neumann C, Hoffmann U, Porstner M, Chen W, Gr&uuml;n JR,
                                    Baumgrass R, Matz M, L&ouml;hning M, Scheffold A, Wittmann J, Chang HD, Rajewsky N,
                                    J&auml;ck HM, Radbruch A, Mashreghi MF.
                                    miR-148a is upregulated by Twist1 and T-bet and promotes Th1-cell survival by regulating
                                    the proapoptotic gene Bim.
                                    Eur J Immunol. (2014) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/25486906"
                                                             target="_blank"  rel="noreferrer">PMID: 25486906</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Zimmermann M, Kalus U, Y&uuml;rek S, Koscielny J, Hoppe B.
                                    Angiotensin converting enzyme intron 16 insertion/deletion genotype is associated with
                                    plasma C-reactive protein concentration in uteroplacental dysfunction.
                                    J Renin Angiotensin Aldosterone Syst. (2014) <a className="pubmedlink"
                                                                                    href="http://www.ncbi.nlm.nih.gov/pubmed/25155623"
                                                                                    target="_blank"  rel="noreferrer">PMID: 25155623</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ghannam K, Martinez-Gamboa L, Spengler L, Krause S, <b>Smiljanovic B</b>, <b>Bonin M</b>,
                                    Bhattarai S, Gr&uuml;tzkau A, Burmester GR, <b>H&auml;upl T</b>, Feist E.
                                    Upregulation of Immunoproteasome Subunits in Myositis Indicates Active Inflammation with
                                    Involvement of Antigen Presenting Cells, CD8 T-Cells and IFNγ.
                                    PLoS One (2014) <a className="pubmedlink"
                                                       href="http://www.ncbi.nlm.nih.gov/pubmed/25098831" target="_blank"  rel="noreferrer">PMID:
                                    25098831</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kupfer P, Huber R, Weber M, Vlaic S, <b>H&auml;upl T</b>, Koczan D, Guthke R, Kinne RW.
                                    Novel application of multi-stimuli network inference to synovial fibroblasts of
                                    rheumatoid arthritis patients.
                                    BMC Med Genomics (2014) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/24989895"
                                                               target="_blank"  rel="noreferrer">PMID: 24989895</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Woetzel D, Huber R, Kupfer P, Pohlers D, Pfaff M, Driesch D, <b>H&auml;upl T</b>, Koczan
                                    D, Stiehl P, Guthke R, Kinne RW.
                                    Identification of rheumatoid arthritis and osteoarthritis patients by
                                    transcriptome-based rule set generation.
                                    Arthritis Res Ther. (2014) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/24690414"
                                                                  target="_blank"  rel="noreferrer">PMID: 24690414</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Stuhlm&uuml;ller B, <b>H&auml;upl T</b>, Burmester GR, Kinne RW.
                                    Prerequisites to Define and to Validate Therapy-Predicitive Blood Biomarkers.
                                    (2014) <a className="pubmedlink"
                                              href={file}
                                              target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Bonin M</b>, Kokatjuhha J, Mans K, Gr&uuml;tzkau A, <b>Smiljanovic
                                    B</b>, <b>S&ouml;rensen T</b>, <b>H&auml;upl T</b>.
                                    Identification of geneexpression networks in different immunological states.
                                    Ann Rheum Dis. (2014) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/73/Suppl_1/A84.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Bonin M</b>, Weidel L, Schendel P, Mans K, Flemming S, Gr&uuml;tzkau A, <b>Smiljanovic
                                    B</b>, <b>S&ouml;rensen T</b>, G&uuml;nther S, <b>H&auml;upl T</b>.
                                    BioConPages - Comparison of DNA-Methylation and gene expression in different immune
                                    cells.
                                    Ann Rheum Dis. (2014) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/73/Suppl_1/A84.1"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Stuhlm&uuml;ller B, Maslinski W, Vogl T, <b>Pade S</b>, Backhaus
                                    M, Burmester GR, Radbruch A, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    From tissue- and cell-specific transcriptomes to candidate markers in rheumatoud
                                    arthritis.
                                    Ann Rheum Dis. (2014) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/73/Suppl_1/A7.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ghannam K, Martinez-Gamboa L, Spengler L, Krause S, <b>Smiljanovic B</b>, <b>Bonin M</b>,
                                    Gr&uuml;tzkau A, Burmester GR, <b>H&auml;upl T</b>, Feist E.
                                    OP0131 Upregulation of Immunoproteasome Subunits PSMB8 and PSMB9 in Myositis Indicates
                                    Active Inflammation with Involvement of Antigen Presenting Cells, CD8+ T-Cells and IFN
                                    Gamma.
                                    Ann Rheum Dis. (2014) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/73/Suppl_2/110.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Duroux-Richard I, Roubert C, Ammari M, Pr&eacute;sumey J, Gr&uuml;n JR, <b>H&auml;upl
                                    T</b>, Gr&uuml;tzkau A, Lecellier CH, Jorgensen C, Apparailly F.
                                    MIR-125B controls mitochondrial functions and dynamics in monocytes.
                                    Ann Rheum Dis. (2014) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/73/Suppl_1/A80.3"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kyogoku C, <b>Smiljanovic B</b>, Gr&uuml;n JR, Schulte-Wrede U, Alexander T, Biesen R,
                                    Hiepe F, <b>H&auml;upl T</b>, Radbruch A, Gr&uuml;tzkau A.
                                    T helper lymphocytes and monocytes as biosensors of type I interferon responses in viral
                                    infection and autoimmunity.
                                    Ann Rheum Dis. (2014) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/73/Suppl_1/A82.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2013" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2013</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Kyogoku C, <b>Smiljanovic B</b>, Gr&uuml;n JR, Biesen R, Schulte-Wrede U, <b>H&auml;upl
                                    T</b>, Hiepe F, Alexander T, Radbruch A, Gr&uuml;tzkau A.
                                    Cell-specific type I IFN signatures in autoimmunity and viral infection: what makes the
                                    difference?
                                    PLoS One. (2013) <a className="pubmedlink"
                                                        href="http://www.ncbi.nlm.nih.gov/pubmed/24391825" target="_blank"  rel="noreferrer">PMID:
                                    24391825</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ullah M, Stich S, <b>H&auml;upl T</b>, Eucker J, Sittinger M, Ringe J.
                                    Reverse differentiation as a gene filtering tool in genome expression profiling of
                                    adipogenesis for fat marker gene selection and their analysis.
                                    PLoS One. (2013) <a className="pubmedlink"
                                                        href="http://www.ncbi.nlm.nih.gov/pubmed/23922792" target="_blank"  rel="noreferrer">PMID:
                                    23922792</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Cobb JE, Plant D, Flynn E, Tadjeddine M, Dieud&eacute; P, Corn&eacute;lis F, Arlestig L,
                                    Dahlqvist SR, Goulielmos G, Boumpas DT, Sidiropoulos P, Krintel SB, Ornbjerg LM, Hetland
                                    ML, Klareskog L, <b>H&auml;upl T</b>, Filer A, Buckley CD, Raza K, Witte T, Schmidt RE,
                                    Fitzgerald O, Veale D, Eyre S, Worthington J.
                                    Identification of the tyrosine-protein phosphatase non-receptor type 2 as a rheumatoid
                                    arthritis susceptibility locus in europeans.
                                    PLoS One. (2013) <a className="pubmedlink"
                                                        href="http://www.ncbi.nlm.nih.gov/pubmed/23840476" target="_blank"  rel="noreferrer">PMID:
                                    23840476</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Stuhlm&uuml;ller B, Feist E, <b>H&auml;upl T</b>, Burmester GR, Pipitone N.
                                    New aspects on the pathogenesis of myositis.
                                    Z Rheumatol. (2013) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/23515563"
                                                           target="_blank"  rel="noreferrer">PMID: 23515563</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Weix J</b>, <b>H&auml;upl T</b>, Raio L, Villiger PM, F&ouml;rger F.
                                    The physiologic increase in expression of some type I IFN-inducible genes during
                                    pregnancy is not associated with improved disease activity in pregnant patients with
                                    rheumatoid arthritis.
                                    Transl Res. (2013) <a className="pubmedlink"
                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/23507374"
                                                          target="_blank"  rel="noreferrer">PMID: 23507374</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Bonin M</b>, Flemming S, G&uuml;nther S, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    Combined Analysis of Epigenetic and Transcriptional Profiles in Different Immune Cells
                                    Identifies Hot Spots of Gene Regulation by DNA-Methylation.
                                    Ann Rheum Dis. (2013) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/72/Suppl_1/A49.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, <b>S&ouml;rensen T</b>, <b>Smiljanovic B</b>, <b>Bonin M</b>,
                                    Gr&uuml;tzkau A, Nikolaou C, Pandis I, Kollias G, Rowe A.
                                    Comparative Transcriptome Analysis of Human and Mouse Synovial Fibroblast Responses to
                                    TNF.
                                    Ann Rheum Dis. (2013) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/72/Suppl_1/A50.1"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Stuhlm&uuml;ller B, <b>Pade S</b>, Backhaus M, Burmester GR,
                                    Radbruch A, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    Synovial Tissue Transcriptome and Synovial Fluid Proteome have Stronger Discriminatory
                                    Power than Serum in Dissecting Inflammation in RA.
                                    Ann Rheum Dis. (2013) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/72/Suppl_1/A81.1"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>S&ouml;rensen T</b>, Schulte-Wrede U, <b>Pade S</b>, Hirseland H, Burmester GR,
                                    Radbruch A, Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    Automated and Standardised Analysis for High Dimensional Cytometric Data Provides New
                                    Options for Complex Cell-Associated Biomarker Screening.
                                    Ann Rheum Dis. (2013) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/72/Suppl_1/A72.3"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kyogoku C, <b>Smiljanovic B</b>, Gr&uuml;n JR, Alexander T, Biesen R, Hiepe
                                    F, <b>H&auml;upl T</b>, Radbruch A, Gr&uuml;tzkau A.
                                    Cell-specific type I Interferon signatures in autoimmunity and viral infection: what
                                    makes the difference?.
                                    Ann Rheum Dis. (2013) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/72/Suppl_1/A6.1"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Stuhlm&uuml;ller B, Maslinski W, Burmester GR, Radbruch A,
                                    Gr&uuml;tzkau A, <b>H&auml;upl T</b>.
                                    Dissecting disease-specific differences in RA and OA by transcriptome analyses of
                                    synovial tissue, blood and bone marrow monocytes.
                                    Ann Rheum Dis. (2013) <a className="pubmedlink"
                                                             href="http://ard.bmj.com/content/72/Suppl_1/A73.2"
                                                             target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Scherer HU, Burmester GR, <b>H&auml;upl T</b>.
                                    Biomarkers and personalized medicine.
                                    Z Rheumatol. (2013) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/23223890"
                                                           target="_blank"  rel="noreferrer">PMID: 23223890</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ebner F, Brandt C, <b>Thiele P</b>, Richter D, Schliesser U, Siffrin V, Schueler J,
                                    Stubbe T, Ellinghaus A, Meisel C, Sawitzki B, Nitsch R.
                                    Microglial activation milieu controls regulatory T cell responses.
                                    J Immunol. (2013) <a className="pubmedlink"
                                                         href="http://www.ncbi.nlm.nih.gov/pubmed/24146044" target="_blank"  rel="noreferrer">PMID:
                                    24146044</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2012" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2012</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Hassert R, Pagel M, Ming Z, <b>H&auml;upl T</b>, Abel B, Braun K, Wiessler M,
                                    Beck-Sickinger AG.
                                    Biocompatible Silicon Surfaces through Orthogonal Click Chemistries and a High Affinity
                                    Silicon Oxide Binding Peptide.
                                    Bioconjug Chem. (2012) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/22989005"
                                                              target="_blank"  rel="noreferrer">PMID: 22989005</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Gr&uuml;n JR, Biesen R, Schulte-Wrede U, Baumgrass R,
                                    Stuhlm&uuml;ller B, Maslinski W, Hiepe F, Burmester GR, Radbruch A, <b>H&auml;upl T</b>,
                                    Gr&uuml;tzkau A.
                                    The multifaceted balance of TNF&alpha; and type I/II interferon responses in SLE and RA:
                                    how monocytes manage the impact of cytokines.
                                    J Mol Med. (2012) <a className="pubmedlink"
                                                         href="http://www.ncbi.nlm.nih.gov/pubmed/22610275" target="_blank"  rel="noreferrer">PMID:
                                    22610275</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Appel H, Backhaus M, Burkhardt H, Gr&uuml;nke M, Gr&uuml;tzkau A,
                                    Hoppe B, Listing J, Ostendorf B, Rudwaleit M, Sieper J, Skapenko A, Skriner
                                    K, <b>S&ouml;rensen T</b>, Stuhlm&uuml;ller B, Zink A, Schulze-Koops H, Burmester GR.
                                    Biomarkers in rheumatology: Biomarkers and imaging for the diagnosis and stratification
                                    of rheumatoid arthritis and spondylarthritis in the ArthroMark network funded by the
                                    Federal Ministry of Education and Research.
                                    Z Rheumatol. (2012) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/22546912"
                                                           target="_blank"  rel="noreferrer">PMID: 22546912</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kruger JP, Endres M, Neumann K, Stuhlmuller B, Morawietz L, <b>H&auml;upl T</b>, Kaps C.
                                    Chondrogenic differentiation of human subchondral progenitor cells is affected by
                                    synovial fluid from donors with osteoarthritis or rheumatoid arthritis.
                                    J Orthop Surg Res. (2012) <a className="pubmedlink"
                                                                 href="http://www.ncbi.nlm.nih.gov/pubmed/22414301"
                                                                 target="_blank"  rel="noreferrer">PMID: 22414301</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Jullien N, Maudinet A, Leloutre B, Ringe J, <b>H&auml;upl T</b>, Marie PJ.
                                    Downregulation of ErbB3 By Wnt3a Contributes to Wnt-induced osteoblast differentiation
                                    in mesenchymal cells.
                                    J Cell Biochem. (2012) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/22274864"
                                                              target="_blank"  rel="noreferrer">PMID: 22274864</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Hoppe B, <b>H&auml;upl T</b>, Skapenko A, Ziemer S, Tauber R, Salama A, Schulze-Koops H,
                                    Burmester GR, D&ouml;rner T.
                                    Fibrinogen and factor XIII A-subunit genotypes interactively influence C-reactive
                                    protein levels during inflammation.
                                    Ann Rheum Dis. (2012) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/22267327"
                                                             target="_blank"  rel="noreferrer">PMID: 22267327</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Weix J</b>, F&ouml;rger F, <b>H&auml;upl T</b>, Surbek D, Ostensen M, Villiger PM.
                                    Influence of pregnancy on the adipocytokine and PPAR pathways in peripheral blood
                                    mononuclear cells of healthy donors and RA patients.
                                    Arthritis Rheum. (2012) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/22231457"
                                                               target="_blank"  rel="noreferrer">PMID: 22231457</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2011" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2011</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Hoppe B, Burmester GR, <b>H&auml;upl T</b>.
                                    Prothrombin 20210G>A genotype and C-reactive protein level.
                                    Blood. (2011) <a className="pubmedlink"
                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/22021457" target="_blank"  rel="noreferrer">PMID:
                                    22021457</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Biesen R, <b>H&auml;upl T</b>.
                                    DNA Microarrays.
                                    Z Rheumatol. (2011) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/21956826"
                                                           target="_blank"  rel="noreferrer">PMID: 21956826</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Menssen A, <b>H&auml;upl T</b>, Sittinger M, Delorme B, Charbord P, Ringe J.
                                    Differential gene expression profiling of human bone marrow-derived mesenchymal stem
                                    cells during adipogenic development.
                                    BMC Genomics (2011) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/21943323"
                                                           target="_blank"  rel="noreferrer">PMID: 21943323</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Bal G, Kamhieh-Milz J, Futschik M, <b>H&auml;upl T</b>, Salama A, Moldenhauer A.
                                    Transcriptional profiling of the hematopoietic support of interleukin-stimulated human
                                    umbilical vein endothelial cells (HUVECs).
                                    Cell Transplant. (2011) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/21669038"
                                                               target="_blank"  rel="noreferrer">PMID: 21669038</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Scheel T, Gursche A, Zacher J, <b>H&auml;upl T</b>, Berek C.
                                    V-region gene analysis of locally defined synovial B and plasma cells reveals selected B
                                    cell expansion and accumulation of plasma cell clones in rheumatoid arthritis.
                                    Arthritis Rheum. (2011) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/20882667"
                                                               target="_blank"  rel="noreferrer">PMID: 20882667</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2010" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2010</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Shahab-Osterloh S, Witte F, Hoffmann A, Winkel A, Laggies S, Neumann B, Seiffart V,
                                    Lindenmaier W, Gruber AD, Ringe J, <b>H&auml;upl T</b>, Thorey F, Willbold E, Corbeau P,
                                    Gross G.
                                    Mesenchymal stem cell-dependent formation of heterotopic tendon-bone insertions
                                    (osteotendinous junctions).
                                    Stem Cells. (2010) <a className="pubmedlink"
                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/20882636"
                                                          target="_blank"  rel="noreferrer">PMID: 20882636</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Slansky E, Li J, <b>H&auml;upl T</b>, Morawietz L, Krenn V, Pessler F.
                                    Quantitative determination of the diagnostic accuracy of the synovitis score and its
                                    components.
                                    Histopathology. (2010) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/20840673"
                                                              target="_blank"  rel="noreferrer">PMID: 20840673</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Endres M, Andreas K, Kalwitz G, Freymann U, Neumann K, Ringe J, Sittinger
                                    M, <b>H&auml;upl T</b>, Kaps C.
                                    Chemokine profile of synovial fluid from normal, osteoarthritis and rheumatoid arthritis
                                    patients: CCL25, CXCL10 and XCL1 recruit human subchondral mesenchymal progenitor cells.
                                    Osteoarthritis Cartilage. (2010) <a className="pubmedlink"
                                                                        href="http://www.ncbi.nlm.nih.gov/pubmed/20709179"
                                                                        target="_blank"  rel="noreferrer">PMID: 20709179</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>Smiljanovic B</b>, Gr&uuml;n JR, Steinbrich-Z&ouml;llner M, Stuhlm&uuml;ller
                                    B, <b>H&auml;upl T</b>, Burmester GR, Radbruch A, Gr&uuml;tzkau A, Baumgrass R.
                                    Defining TNF&alpha;- and LPS-induced gene signatures in monocytes to unravel the
                                    complexity of peripheral blood transcriptomes in health and disease.
                                    J Mol Med. (2010) <a className="pubmedlink"
                                                         href="http://www.ncbi.nlm.nih.gov/pubmed/20640394" target="_blank"  rel="noreferrer">PMID:
                                    20640394</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kaltz N, Ringe J, Holzwarth C, Charbord P, Niemeyer M, Jacobs VR, Peschel
                                    C, <b>H&auml;upl T</b>, Oostendorp RA.
                                    Novel markers of mesenchymal stem cells defined by genome-wide gene expression analysis
                                    of stromal cells from different sources.
                                    Exp Cell Res. (2010) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/20599957"
                                                            target="_blank"  rel="noreferrer">PMID: 20599957</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Hamidouche Z, Fromigu&eacute; O, Ringe J, <b>H&auml;upl T</b>, Marie PJ.
                                    Crosstalks between integrin alpha 5 and IGF2/IGFBP2 signalling trigger human bone
                                    marrow-derived mesenchymal stromal osteogenic differentiation.
                                    BMC Cell Biol. (2010) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/20573191"
                                                             target="_blank"  rel="noreferrer">PMID: 20573191</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    G&ouml;hring AR, L&uuml;bke C, Andreas K, Kaps C, <b>H&auml;upl T</b>, Pruss A, Perka C,
                                    Sittinger M, Ringe J.
                                    Tissue-engineered cartilage of porcine and human origin as in vitro test system in
                                    arthritis research.
                                    Biotechnol Prog. (2010) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/20306542"
                                                               target="_blank"  rel="noreferrer">PMID: 20306542</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Charbord P, Livne E, Gross G, <b>H&auml;upl T</b>, Neves NM, Marie P, Bianco P,
                                    Jorgensen C.
                                    Human Bone Marrow Mesenchymal Stem Cells: A Systematic Reappraisal Via the Genostem
                                    Experience.
                                    Stem Cell Rev. (2010) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/20198518"
                                                             target="_blank"  rel="noreferrer">PMID: 20198518</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Scherer HU, van der Woude D, Ioan-Facsinay A, El Bannoudi H, Trouw LA, Koeleman CA, Wang
                                    J, <b>H&auml;upl T</b>, Burmester GR, Deelder AM, Huizinga TW, Wuhrer M, Toes RE.
                                    Glycan profiling of anti-citrullinated protein antibodies (ACPA) isolated from human
                                    serum and synovial fluid.
                                    Arthritis Rheum. (2010) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/20178128"
                                                               target="_blank"  rel="noreferrer">PMID: 20178128</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Miraoui H, Ringe J, <b>H&auml;upl T</b>, Marie PJ.
                                    Increased EFG- and PDGFalpha-receptor signaling by mutant FGF-receptor 2 contributes to
                                    osteoblast dysfunction in Apert craniosynostosis.
                                    Hum Mol Genet. (2010) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/20124286"
                                                             target="_blank"  rel="noreferrer">PMID: 20124286</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Stuhlm&uuml;ller B, Gr&uuml;tzkau A, Radbruch A, Burmester GR.
                                    Does gene expression analysis inform us in rheumatoid arthritis?
                                    Ann Rheum Dis. (2010) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/19995742"
                                                             target="_blank"  rel="noreferrer">PMID: 19995742</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Stuhlm&uuml;ller B, <b>H&auml;upl T</b>, Hernandez MM, Gr&uuml;tzkau A, Kuban RJ, Tandon
                                    N, Voss JW, Salfeld J, Kinne RW, Burmester GR.
                                    CD11c as a transcriptional biomarker to predict response to anti-TNF monotherapy with
                                    adalimumab in patients with rheumatoid arthritis.
                                    Clin Pharmacol Ther. (2010) <a className="pubmedlink"
                                                                   href="http://www.ncbi.nlm.nih.gov/pubmed/20032971"
                                                                   target="_blank"  rel="noreferrer">PMID: 20032971</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2009" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2009</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Kr&uuml;ger JP, Endres M, Neumann K, <b>H&auml;upl T</b>, Erggelet C, Kaps C.
                                    Chondrogenic differentiation of human subchondral progenitor cells is impaired by
                                    rheumatoid arthritis synovial fluid.
                                    J Orthop Res. (2009) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/20041492"
                                                            target="_blank"  rel="noreferrer">PMID: 20041492</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Feron F, Delorme B, Nivet E, Gaillard J, <b>H&auml;upl T</b>, Ringe J, Deveze A, Magnan
                                    J, Sohier J, Khrestchatisky M, Roman F, Charbord P, Senseb&eacute; L, Layrolle P.
                                    The human nose harbours a niche of olfactory ecto-mesenchymal stem cells displaying
                                    neurogenic and osteogenic properties.
                                    Stem Cells Dev. (2009) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/19905894"
                                                              target="_blank"  rel="noreferrer">PMID: 19905894</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Gaber T, <b>H&auml;upl T</b>, Sandig G, Tykwinska K, Fangradt M, Tschirschmann M, Hahne
                                    M, Dziurla R, Erekul K, Lautenbach M, Kolar P, Burmester GR, Buttgereit F.
                                    Adaptation of human CD4+ T cells to pathophysiological hypoxia: a transcriptome
                                    analysis.
                                    J Rheumatol. (2009) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/19884271"
                                                           target="_blank"  rel="noreferrer">PMID: 19884271</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Hamidouche Z, Fromigu&eacute; O, Ringe J, <b>H&auml;upl T</b>, Vaudin P, Pagès JC,
                                    Srouji S, Livne E, Marie PJ.
                                    Priming integrin alpha5 promotes human mesenchymal stromal cell osteoblast
                                    differentiation and osteogenesis.
                                    Proc Natl Acad Sci USA. (2009) <a className="pubmedlink"
                                                                      href="http://www.ncbi.nlm.nih.gov/pubmed/19843692"
                                                                      target="_blank"  rel="noreferrer">PMID: 19843692</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Jakobs M, <b>H&auml;upl T</b>, Krenn V, Guenther R.
                                    MMP- and FAP-mediated non-inflammation-related destruction of cartilage and bone in
                                    rheumatoid arthritis.
                                    Z Rheumatol. (2009) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/19593575"
                                                           target="_blank"  rel="noreferrer">PMID: 19593575</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Delorme B, Ringe J, Pontikoglou C, Gaillard J, Langonn&eacute; A, Senseb&eacute; L, Noël
                                    D, Jorgensen C, <b>H&auml;upl T</b>, Charbord P.
                                    Specific lineage-priming of bone marrow mesenchymal stem cells provides the molecular
                                    framework for their plasticity.
                                    Stem Cells. (2009) <a className="pubmedlink"
                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/19418444"
                                                          target="_blank"  rel="noreferrer">PMID: 19418444</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Stich S, Haag M, <b>H&auml;upl T</b>, Sezer O, Notter M, Kaps C, Sittinger M, Ringe J.
                                    Gene expression profiling of human mesenchymal stem cells chemotactically induced with
                                    CXCL12.
                                    Cell Tissue Res. (2009) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/19296133"
                                                               target="_blank"  rel="noreferrer">PMID: 19296133</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Menssen A, Edinger G, Gr&uuml;n JR, Haase U, Baumgrass R, Gr&uuml;tzkau A, Radbruch A,
                                    Burmester GR, <b>H&auml;upl T</b>.
                                    SiPaGene: A new repository for instant online retrieval, sharing and meta-analyses of
                                    GeneChip expression data.
                                    BMC Genomics. (2009) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/19265543"
                                                            target="_blank"  rel="noreferrer">PMID: 19265543</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Mrugala D, Dossat N, Ringe J, Delorme B, Coffy A, Bony C, Charbord P, <b>H&auml;upl
                                    T</b>, Daures JP, Noël D, Jorgensen C.
                                    Gene expression profile of multipotent mesenchymal stromal cells: Identification of
                                    pathways common to TGFbeta3/BMP2-induced chondrogenesis.
                                    Cloning Stem Cells. (2009) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/19196040"
                                                                  target="_blank"  rel="noreferrer">PMID: 19196040</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Andreas K, <b>H&auml;upl T</b>, L&uuml;bke C, Ringe J, Morawietz L, Wachtel A, Sittinger
                                    M, Kaps C.
                                    Antirheumatic drug response signatures in human chondrocytes: potential molecular
                                    targets to stimulate cartilage regeneration.
                                    Arthritis Res Ther. (2009) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/19192274"
                                                                  target="_blank"  rel="noreferrer">PMID: 19192274</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kalwitz G, Endres M, Neumann K, Skriner K, Ringe J, Sezer O, Sittinger M, <b>H&auml;upl
                                    T</b>, Kaps C.
                                    Gene expression profile of adult human bone marrow-derived mesenchymal stem cells
                                    stimulated by the chemokine CXCL7.
                                    Int J Biochem Cell Biol. (2009) <a className="pubmedlink"
                                                                       href="http://www.ncbi.nlm.nih.gov/pubmed/18707017"
                                                                       target="_blank"  rel="noreferrer">PMID: 18707017</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Hoppe B, <b>H&auml;upl T</b>, Egerer K, Gruber R, Kiesewetter H, Salama A, Burmester GR,
                                    D&ouml;rner T.
                                    Influence of peptidylarginine deiminase type 4 genotype and shared epitope on clinical
                                    characteristics and autoantibody profile of rheumatoid arthritis.
                                    Ann Rheum Dis. (2009) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/18633125"
                                                             target="_blank"  rel="noreferrer">PMID: 18633125</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2008" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2008</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Bramlage CP, Kaps C, Ungeth&uuml;m U, Bramlage P, Koziolek M, Wessels J, Krenn V, Pruss
                                    A, M&uuml;ller GA, Strutz F, Burmester GR, <b>H&auml;upl T</b>.
                                    Modulatory effects of inflammation and therapy on GDF-5 expression in rheumatoid
                                    arthritis synovium.
                                    Scand J Rheumatol. (2008) <a className="pubmedlink"
                                                                 href="http://www.ncbi.nlm.nih.gov/pubmed/18830904"
                                                                 target="_blank"  rel="noreferrer">PMID: 18830904</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Østensen M, Gr&uuml;tzkau A, Radbruch A, Burmester GR, Villiger PM.
                                    Reactivation of rheumatoid arthritis after pregnancy: increased phagocyte and recurring
                                    lymphocyte gene activity.
                                    Arthritis Rheum. (2008) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/18821679"
                                                               target="_blank"  rel="noreferrer">PMID: 18821679</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Niesner U, Albrecht I, Janke M, Doebis C, Loddenkemper C, Lexberg MH, Eulenburg K,
                                    Kreher S, Koeck J, Baumgrass R, Bonhagen K, Kamradt T, Enghard P, Humrich JY, Rutz S,
                                    Schulze-Topphoff U, Aktas O, Bartfeld S, Radbruch H, Hegazy AN, L&ouml;hning M, Baumgart
                                    DC, Duchmann R, Rudwaleit M, <b>H&auml;upl T</b>, Gitelman I, Krenn V, Gruen J, Sieper
                                    J, Zeitz M, Wiedenmann B, Zipp F, Hamann A, Janitz M, Scheffold A, Burmester GR, Chang
                                    HD, Radbruch A.
                                    Autoregulation of Th1-mediated inflammation by twist1.
                                    J Exp Med. (2008) <a className="pubmedlink"
                                                         href="http://www.ncbi.nlm.nih.gov/pubmed/18663125" target="_blank"  rel="noreferrer">PMID:
                                    18663125</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kaltz N, Funari A, Hippauf S, Delorme B, Noël D, Riminucci M, Jacobs VR, <b>H&auml;upl
                                    T</b>, Jorgensen C, Charbord P, Peschel C, Bianco P, Oostendorp RA.
                                    In vivo osteoprogenitor potency of human stromal cells from different tissues does not
                                    correlate with expression of POU5F1 or its pseudogenes.
                                    Stem Cells. (2008) <a className="pubmedlink"
                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/18617685"
                                                          target="_blank"  rel="noreferrer">PMID: 18617685</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Østensen M, Gr&uuml;tzkau A, Burmester GR, Villiger PM.
                                    Interaction between rheumatoid arthritis and pregnancy: correlation of molecular data
                                    with clinical disease activity measures.
                                    Rheumatology (Oxford). (2008) <a className="pubmedlink"
                                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/18504279"
                                                                     target="_blank"  rel="noreferrer">PMID: 18504279</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Tripmacher R, Gaber T, Dziurla R, <b>H&auml;upl T</b>, Erekul K, Gr&uuml;tzkau A,
                                    Tschirschmann M, Scheffold A, Radbruch A, Burmester GR, Buttgereit F.
                                    Human CD4(+) T cells maintain specific functions even under conditions of extremely
                                    restricted ATP production.
                                    Eur J Immunol. (2008) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/18493983"
                                                             target="_blank"  rel="noreferrer">PMID: 18493983</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ringe J, Leinhase I, Stich S, Loch A, Neumann K, Haisch A, <b>H&auml;upl T</b>, Manz R,
                                    Kaps C, Sittinger M.
                                    Human mastoid periosteum-derived stem cells: promising candidates for skeletal tissue
                                    engineering.
                                    J Tissue Eng Regen Med. (2008)<a className="pubmedlink"
                                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/18383554"
                                                                     target="_blank"  rel="noreferrer">PMID: 18383554</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Biesen R, Demir C, Barkhudarova F, Gr&uuml;n JR, Steinbrich-Z&ouml;llner M, Backhaus
                                    M, <b>H&auml;upl T</b>, Rudwaleit M, Riemekasten G, Radbruch A, Hiepe F, Burmester GR,
                                    Gr&uuml;tzkau A.
                                    Sialic acid-binding Ig-like lectin 1 expression in inflammatory and resident monocytes
                                    is a potential biomarker for monitoring disease activity and success of therapy in
                                    systemic lupus erythematosus.
                                    Arthritis Rheum. (2008) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/18383365"
                                                               target="_blank"  rel="noreferrer">PMID: 18383365</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Guenther R, Krenn V, <b>H&auml;upl T</b>.
                                    Expression analyses for rheumatoid arthritis.
                                    Z Rheumatol. (2008) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/18210133"
                                                           target="_blank"  rel="noreferrer">PMID: 18210133</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Andreas K, L&uuml;bke C, <b>H&auml;upl T</b>, Dehne T, Morawietz L, Ringe J, Kaps C,
                                    Sittinger M.
                                    Key regulatory molecules of cartilage destruction in rheumatoid arthritis: an in vitro
                                    study.
                                    Arthritis Res Ther. (2008) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/18205922"
                                                                  target="_blank"  rel="noreferrer">PMID: 18205922</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Delorme B, Ringe J, Gallay N, Le Vern Y, Kerboeuf D, Jorgensen C, Rosset P,
                                    Senseb&eacute; L, Layrolle P, <b>H&auml;upl T</b>, Charbord P.
                                    Specific plasma membrane protein phenotype of culture-amplified and native human bone
                                    marrow mesenchymal stem cells.
                                    Blood. (2008) <a className="pubmedlink"
                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/18086871" target="_blank"  rel="noreferrer">PMID:
                                    18086871</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kastrinaki MC, Sidiropoulos P, Roche S, Ringe J, Lehmann S, Kritikos H, Vlahava VM,
                                    Delorme B, Eliopoulos GD, Jorgensen C, Charbord P, <b>H&auml;upl T</b>, Boumpas DT,
                                    Papadaki HA.
                                    Functional, molecular and proteomic characterisation of bone marrow mesenchymal stem
                                    cells in rheumatoid arthritis.
                                    Ann Rheum Dis. (2008) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/17921184"
                                                             target="_blank"  rel="noreferrer">PMID: 17921184</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2007" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2007</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Gr&uuml;tzkau A, Gr&uuml;n JR, <b>H&auml;upl T</b>, Burmester GR, Radbruch A.
                                    Gene expression in inflammatory rheumatic diseases.
                                    Dtsch Med Wochenschr. (2007) <a className="pubmedlink"
                                                                    href="http://www.ncbi.nlm.nih.gov/pubmed/17823881"
                                                                    target="_blank"  rel="noreferrer">PMID: 17823881</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Endres M, Neumann K, <b>H&auml;upl T</b>, Erggelet C, Ringe J, Sittinger M, Kaps C.
                                    Synovial fluid recruits human mesenchymal progenitors from subchondral spongious bone
                                    marrow.
                                    J Orthop Res. (2007) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/17549723"
                                                            target="_blank"  rel="noreferrer">PMID: 17549723</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Burmester GR, Giannitsis E, Rohrlach T, Spanuth E, Parsch H, Brune
                                    K.
                                    N-terminal prohormone brain natriuretic peptide: a biomarker for detecting
                                    cardiovascular risks in patients with rheumatoid arthritis or osteoarthritis.
                                    Ann Rheum Dis. (2007) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/17513573"
                                                             target="_blank"  rel="noreferrer">PMID: 17513573</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Neumann K, Endres M, Ringe J, Flath B, Manz R, <b>H&auml;upl T</b>, Sittinger M, Kaps C.
                                    BMP7 promotes adipogenic but not osteo-/chondrogenic differentiation of adult human bone
                                    marrow-derived stem cells in high-density micro-mass culture.
                                    J Cell Biochem. (2007) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/17497692"
                                                              target="_blank"  rel="noreferrer">PMID: 17497692</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Yahyawi M, L&uuml;bke C, Ringe J, Rohrlach T, Burmester GR,
                                    Sittinger M, Kaps C.
                                    Gene expression profiling of rheumatoid arthritis synovial cells treated with
                                    antirheumatic drugs.
                                    J Biomol Screen. (2007) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/17379860"
                                                               target="_blank"  rel="noreferrer">PMID: 17379860</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ringe J, <b>H&auml;upl T</b>, Sittinger M.
                                    Future of tissue engineering in rheumatic diseases.
                                    Expert Opin Biol Ther. (2007) <a className="pubmedlink"
                                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/17309320"
                                                                     target="_blank"  rel="noreferrer">PMID: 17309320</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2006" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2006</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Krenn V, Morawietz L, Burmester GR, Kinne RW, Mueller-Ladner U, Muller B, <b>H&auml;upl
                                    T</b>.
                                    Synovitis score: discrimination between chronic low-grade and high-grade synovitis.
                                    Histopathology. (2006) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/16978198"
                                                              target="_blank"  rel="noreferrer">PMID: 16978198</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Krenn V, Morawietz L, K&ouml;nig B, Otto M, Kriegsmann J, K&ouml;penik A, B&ouml;hme
                                    T, <b>H&auml;upl T</b>.
                                    Low-grade-/high-grade-synovitis: synovitis-score as a gold standard?.
                                    Orthopade. (2006) <a className="pubmedlink"
                                                         href="http://www.ncbi.nlm.nih.gov/pubmed/16819616" target="_blank"  rel="noreferrer">PMID:
                                    16819616</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Mahr S, Burmester GR, Hilke D, G&ouml;bel U, Gr&uuml;tzkau A, <b>H&auml;upl T</b>,
                                    Hauschild M, Koczan D, Krenn V, Neidel J, Perka C, Radbruch A, Thiesen HJ, M&uuml;ller
                                    B.
                                    Cis- and trans-acting gene regulation is associated with osteoarthritis.
                                    Am J Hum Genet. (2006) <a className="pubmedlink"
                                                              href="http://www.ncbi.nlm.nih.gov/pubmed/16642435"
                                                              target="_blank"  rel="noreferrer">PMID: 16642435</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kaps C, Frauenschuh S, Endres M, Ringe J, Haisch A, Lauber J, Buer J, Krenn
                                    V, <b>H&auml;upl T</b>, Burmester GR, Sittinger M.
                                    Gene expression profiling of human articular cartilage grafts generated by tissue
                                    engineering.
                                    Biomaterials. (2006) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/16545449"
                                                            target="_blank"  rel="noreferrer">PMID: 16545449</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Bramlage CP, <b>H&auml;upl T</b>, Kaps C, Ungeth&uuml;m U, Krenn V, Pruss A, M&uuml;ller
                                    GA, Strutz F, Burmester GR.
                                    Decrease in expression of bone morphogenetic proteins 4 and 5 in synovial tissue of
                                    patients with osteoarthritis and rheumatoid arthritis.
                                    Arthritis Res Ther. (2006) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/16542506"
                                                                  target="_blank"  rel="noreferrer">PMID: 16542506</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Hoppe B, <b>H&auml;upl T</b>, Gruber R, Kiesewetter H, Burmester GR, Salama A,
                                    D&ouml;rner T.
                                    Detailed analysis of the variability of peptidylarginine deiminase type 4 in German
                                    patients with rheumatoid arthritis: a case-control study.
                                    Arthritis Res Ther. (2006) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/16469113"
                                                                  target="_blank"  rel="noreferrer">PMID: 16469113</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                            <div id="PublikationenSub2005" className="subgroup">
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2005</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Djouad F, Bony C, <b>H&auml;upl T</b>, Uz&eacute; G, Lahlou N, Louis-Plence P,
                                    Apparailly F, Canovas F, Rème T, Sany J, Jorgensen C, Noël D.
                                    Transcriptional profiles discriminate bone marrow-derived and synovium-derived
                                    mesenchymal stem cells.
                                    Arthritis Res Ther. (2005) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/16277684"
                                                                  target="_blank"  rel="noreferrer">PMID: 16277684</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Bramlage CP, <b>H&auml;upl T</b>, Kaps C, Bramlage P, M&uuml;ller GA, Strutz F.
                                    Bone morphogenetic proteins in the skeletal system.
                                    Z Rheumatol. (2005) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/16184350"
                                                           target="_blank"  rel="noreferrer">PMID: 16184350</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Krenn V, Morawietz L, Burmester GR, <b>H&auml;upl T</b>.
                                    Synovialitis score: histopathological grading system for chronic rheumatic and
                                    non-rheumatic synovialitis.
                                    Z Rheumatol. (2005) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/15965818"
                                                           target="_blank"  rel="noreferrer">PMID: 15965818</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Gr&uuml;tzkau A, Gr&uuml;n JR, <b>Bonin M</b>, Stuhlm&uuml;ller B,
                                    Rohrlach T, Kaps C, Rudwaleit M, Morawietz L, Gursche A, Zacher J, Krenn V, Burmester
                                    GR, Radbruch A.
                                    Dissection of expression profiles into functional components: a strategy to identify the
                                    pieces in the puzzle of systems biology.
                                    19th NGFN Meeting (2005) <a className="pubmedlink" href="/#" target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Skriner K, Konthur Z, <b>H&auml;upl T</b>, Adolph K, Burguera G, Sternjak A,
                                    F&ouml;rster A, Lehrach H, Hollidt JM, Burmester GR.
                                    Immunomics in inflammatory rheumatic diseases.
                                    19th NGFN Meeting (2005) <a className="pubmedlink" href="/#" target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Podtschaske M, Hoefer T, Lamottke B, Niesner U, Gr&uuml;n JR, <b>H&auml;upl T</b>,
                                    Burmester GR, Radbruch A, Baumgrass R.
                                    Immediate early gene expression of Il-2 producing and nonproducing cells reflects the
                                    cell fate decision of stimulated human T cells.
                                    19th NGFN Meeting (2005) <a className="pubmedlink" href="/#" target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Gr&uuml;tzkau A, Gr&uuml;n JR, Stuhlm&uuml;ller B, Rudwaleit M, Sieper J, Gerstmayer B,
                                    Baumgrass R, Mahr S, M&uuml;ller-Hilke B, Janitz M, Burmester GR, <b>H&auml;upl T</b>,
                                    Radbruch A.
                                    The transcriptome of monocytes reveals disease-specific alterations in chronic
                                    inflammatory rheumatic diseases.
                                    19th NGFN Meeting (2005) <a className="pubmedlink" href="/#" target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Chakraborty T, Ruocco I, Hossain H, Adler H, Autenrieth I, Bohn E, Burmester GR, Dingel
                                    S, Ehlers S, <b>H&auml;upl T</b>, Hoffmann WH, Horstmann R, Jacobsen M, Keller C, Lang
                                    R, Meyer CG, Radbruch A, Schubert U, Seeger W, Wagner H.
                                    Springende Erreger und Entz&uuml;ndungen ohne Infektion - Das Infektions- und
                                    Entz&uuml;ndungsnetzwerk im Nationalen Genomforschungsnetz (NGFN) / &uuml;bertragbare
                                    Krankheiten mit dramatischen Folgen/Analyse von Entz&uuml;ndungsreaktionen f&uuml;r neue
                                    Heilungsstrategien.
                                    GenomExpress (2005) <a className="pubmedlink"
                                                           href="http://www.ngfn-2.ngfn.de/Dateien/GenomExpress_1.05.pdf"
                                                           target="_blank"  rel="noreferrer">Link</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2004</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Burmester GR, <b>H&auml;upl T</b>.
                                    Strategies using functional genomics in rheumatic diseases.
                                    Autoimmun Rev. (2004) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/15546803"
                                                             target="_blank"  rel="noreferrer">PMID: 15546803</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kaps C, Hoffmann A, Zilberman Y, Pelled G, <b>H&auml;upl T</b>, Sittinger M, Burmester
                                    G, Gazit D, Gross G.
                                    Distinct roles of BMP receptors Type IA and IB in osteo-/chondrogenic differentiation in
                                    mesenchymal progenitors (C3H10T1/2).
                                    Biofactors. (2004) <a className="pubmedlink"
                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/15322331"
                                                          target="_blank"  rel="noreferrer">PMID: 15322331</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Krenn V, Petersen I, <b>H&auml;upl T</b>, Koepenik A, Blind C, Dietel M, Konthur Z,
                                    Skriner K.
                                    Array technology and proteomics in autoimmune diseases.
                                    Pathol Res Pract. (2004) <a className="pubmedlink"
                                                                href="http://www.ncbi.nlm.nih.gov/pubmed/15237918"
                                                                target="_blank"  rel="noreferrer">PMID: 15237918</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Krenn V, Stuhlm&uuml;ller B, Radbruch A, Burmester GR.
                                    Perspectives and limitations of gene expression profiling in rheumatology: new molecular
                                    strategies.
                                    Arthritis Res Ther. (2004) <a className="pubmedlink"
                                                                  href="http://www.ncbi.nlm.nih.gov/pubmed/15225356"
                                                                  target="_blank"  rel="noreferrer">PMID: 15225356</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Sopov I, <b>S&ouml;rensen T</b>, Magbagbeolu M, Jansen L, Beer K, K&uuml;hne-Heid R,
                                    Kirchmayr R, Schneider A, D&uuml;rst M.
                                    Detection of cancer-related gene expression profiles in severe cervical neoplasia.
                                    Int J Cancer. (2004) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/15305373"
                                                            target="_blank"  rel="noreferrer">PMID: 15305373</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2003</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Ringe J, <b>H&auml;upl T</b>, Sittinger M.
                                    Mesenchymal stem cells for tissue engineering of bone and cartilage.
                                    Med Klin (Munich). (2003) <a className="pubmedlink"
                                                                 href="http://www.ncbi.nlm.nih.gov/pubmed/14992201">PMID:
                                    14992201</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Schmitt B, Ringe J, <b>H&auml;upl T</b>, Notter M, Manz R, Burmester GR, Sittinger M,
                                    Kaps C.
                                    BMP2 initiates chondrogenic lineage development of adult human mesenchymal stem cells in
                                    high-density culture.
                                    Differentiation. (2003) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/14686954"
                                                               target="_blank"  rel="noreferrer">PMID: 14686954</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Ringe J, Erggelet C, Kaps C, Burmester GR, Sittinger M.
                                    Tissue engineering: chances and challenges for application in rheumatic diseases.
                                    Z Rheumatol. (2003) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/14648092"
                                                           target="_blank"  rel="noreferrer">PMID: 14648092</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 2002</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    <b>H&auml;upl T</b>, Burmester GR, Stuhlm&uuml;ller B.
                                    New aspects of molecular biology diagnosis. Array technology and expression profile for
                                    characterization of rheumatic diseases.
                                    Z Rheumatol. (2002) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/12426845"
                                                           target="_blank"  rel="noreferrer">PMID: 12426845</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kaps C, Loch A, Haisch A, Smolian H, Burmester GR, <b>H&auml;upl T</b>, Sittinger M.
                                    Human platelet supernatant promotes proliferation but not differentiation of articular
                                    chondrocytes.
                                    Med Biol Eng Comput. (2002) <a className="pubmedlink"
                                                                   href="http://www.ncbi.nlm.nih.gov/pubmed/12227637"
                                                                   target="_blank"  rel="noreferrer">PMID: 12227637</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Krenn V, Morawietz L, <b>H&auml;upl T</b>, Neidel J, Petersen I, K&ouml;nig A.
                                    Grading of chronic synovitis--a histopathological grading system for molecular and
                                    diagnostic pathology.
                                    Pathol Res Pract. (2002) <a className="pubmedlink"
                                                                href="http://www.ncbi.nlm.nih.gov/pubmed/12092767"
                                                                target="_blank"  rel="noreferrer">PMID: 12092767</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Riemekasten G, Ziemer S, <b>H&auml;upl T</b>, Melzer C, Loddenkemper K, Hauptmann S,
                                    Burmester GR, Hiepe F.
                                    Shwartzman phenomenon in a patient with active systemic lupus erythematosus preceding
                                    fatal disseminated intravascular coagulation.
                                    Lupus. (2002) <a className="pubmedlink"
                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/12043882" target="_blank"  rel="noreferrer">PMID:
                                    12043882</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Ringe J, Kaps C, Schmitt B, B&uuml;scher K, Bartel J, Smolian H, Schultz O, Burmester
                                    GR, <b>H&auml;upl T</b>, Sittinger M.
                                    Porcine mesenchymal stem cells. Induction of distinct mesenchymal cell lineages.
                                    Cell Tissue Res. (2002) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/11904768"
                                                               target="_blank"  rel="noreferrer">PMID: 11904768</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Kaps C, Bramlage C, Smolian H, Haisch A, Ungeth&uuml;m U, Burmester GR, Sittinger M,
                                    Gross G, <b>H&auml;upl T</b>.
                                    Bone morphogenetic proteins promote cartilage differentiation and protect engineered
                                    artificial cartilage from fibroblast invasion and destruction.
                                    Arthritis Rheum. (2002) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/11817587"
                                                               target="_blank"  rel="noreferrer">PMID: 11817587</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span >Publication 2000</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Rosen O, Thiel A, Massenkeil G, Hiepe F, <b>H&auml;upl T</b>, Radtke H, Burmester GR,
                                    Gromnica-Ihle E, Radbruch A, Arnold R.
                                    Autologous stem-cell transplantation in refractory autoimmune diseases after in vivo
                                    immunoablation and ex vivo depletion of mononuclear cells.
                                    Arthritis Res. (2000) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/11056673"
                                                             target="_blank"  rel="noreferrer">PMID: 11056673</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 1999</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Sittinger M, Perka C, Schultz O, <b>H&auml;upl T</b>, Burmester GR.
                                    Joint cartilage regeneration by tissue engineering.
                                    Z Rheumatol. (1999) <a className="pubmedlink"
                                                           href="http://www.ncbi.nlm.nih.gov/pubmed/10441839"
                                                           target="_blank"  rel="noreferrer">PMID: 10441839</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Redlich A, Perka C, Schultz O, Spitzer R, <b>H&auml;upl T</b>, Burmester GR, Sittinger
                                    M.
                                    Bone engineering on the basis of periosteal cells cultured in polymer fleeces.
                                    J Mater Sci Mater Med. (1999) <a className="pubmedlink"
                                                                     href="http://www.ncbi.nlm.nih.gov/pubmed/15347948"
                                                                     target="_blank"  rel="noreferrer">PMID: 15347948</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 1998</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Riemekasten G, Marell J, Trebeljahr G, Klein R, Hausdorf G, <b>H&auml;upl T</b>,
                                    Schneider-Mergener J, Burmester GR, Hiepe F.
                                    A novel epitope on the C-terminus of SmD1 is recognized by the majority of sera from
                                    patients with systemic lupus erythematosus.
                                    J Clin Invest. (1998) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/9710444"
                                                             target="_blank"  rel="noreferrer">PMID: 9710444</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Keyszer G, Redlich A, <b>H&auml;upl T</b>, Zacher J, Sparmann M, Engeth&uuml;m U, Gay S,
                                    Burmester GR.
                                    Differential expression of cathepsins B and L compared with matrix metalloproteinases
                                    and their respective inhibitors in rheumatoid arthritis and osteoarthritis: a parallel
                                    investigation by semiquantitative reverse transcriptase-polymerase chain reaction and
                                    immunohistochemistry.
                                    Arthritis Rheum. (1998) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/9704635"
                                                               target="_blank"  rel="noreferrer">PMID: 9704635</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Seki T, Selby J, <b>H&auml;upl T</b>, Winchester R.
                                    Use of differential subtraction method to identify genes that characterize the phenotype
                                    of cultured rheumatoid arthritis synoviocytes.
                                    Arthritis Rheum. (1998) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/9704633"
                                                               target="_blank"  rel="noreferrer">PMID: 9704633</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span >Publication 1997</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    <b>H&auml;upl T</b>, Landgraf S, Netusil P, Biller N, Capiau C, Desmons P, Hauser P,
                                    Burmester GR.
                                    Activation of monocytes by three OspA vaccine candidates: lipoprotein OspA is a potent
                                    stimulator of monokines.
                                    FEMS Immunol Med Microbiol. (1997) <a className="pubmedlink"
                                                                          href="http://www.ncbi.nlm.nih.gov/pubmed/9322065"
                                                                          target="_blank"  rel="noreferrer">PMID: 9322065</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 1994</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Rittig MG, <b>H&auml;upl T</b>, Krause A, Kressel M, Groscurth P, Burmester GR.
                                    Borrelia burgdorferi-induced ultrastructural alterations in human phagocytes: a clue to
                                    pathogenicity?
                                    J Pathol. (1994) <a className="pubmedlink"
                                                        href="http://www.ncbi.nlm.nih.gov/pubmed/7931847" target="_blank"  rel="noreferrer">PMID:
                                    7931847</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    Rittig MG, <b>H&auml;upl T</b>, Burmester GR.
                                    Coiling phagocytosis: a way for MHC class I presentation of bacterial antigens?
                                    Int Arch Allergy Immunol. (1994) <a className="pubmedlink"
                                                                        href="http://www.ncbi.nlm.nih.gov/pubmed/8260849"
                                                                        target="_blank"  rel="noreferrer">PMID: 8260849</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span >Publication 1993</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    <b>H&auml;upl T</b>, Hahn G, Rittig M, Krause A, Schoerner C, Sch&ouml;nherr U, Kalden
                                    JR, Burmester GR.
                                    Persistence of Borrelia burgdorferi in ligamentous tissue from a patient with chronic
                                    Lyme borreliosis.
                                    Arthritis Rheum. (1993) <a className="pubmedlink"
                                                               href="http://www.ncbi.nlm.nih.gov/pubmed/8240439"
                                                               target="_blank"  rel="noreferrer">PMID: 8240439</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span> 1992</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    Rittig MG, Krause A, <b>H&auml;upl T</b>, Schaible UE, Modolell M, Kramer MD,
                                    L&uuml;tjen-Drecoll E, Simon MM, Burmester GR.
                                    Coiling phagocytosis is the preferential phagocytic mechanism for Borrelia burgdorferi.
                                    Infect Immun. (1992) <a className="pubmedlink"
                                                            href="http://www.ncbi.nlm.nih.gov/pubmed/1398932"
                                                            target="_blank"  rel="noreferrer">PMID: 1398932</a>
                                    <br/>
                                </p>
                                <p className="justify">
                                    <b>H&auml;upl T</b>, Burmester GR.
                                    Clinical aspects, diagnosis and therapy of Lyme borreliosis.
                                    Z Arztl Fortbild (Jena). (1992) <a className="pubmedlink"
                                                                       href="http://www.ncbi.nlm.nih.gov/pubmed/1546489"
                                                                       target="_blank"  rel="noreferrer">PMID: 1546489</a>
                                    <br/>
                                </p>
                                <br/>
                                <div className="timeline-item_publikationen">
                                    <h2>
                                        <span >Publication 1989</span>
                                    <div className={"line"}></div></h2>
                                </div>

                                <p className="justify">
                                    <b>H&auml;upl T</b>, Burmester GR, Hahn G, Feige U, Rordorf-Adam C, Kalden JR.
                                    Differential immunological response of patients with rheumatoid arthritis towards two
                                    different Epstein-Barr virus strains: inhibition of interleukin-1 release by the B95-8,
                                    but not the P3HR-1 virus strain.
                                    Rheumatol Int. (1989) <a className="pubmedlink"
                                                             href="http://www.ncbi.nlm.nih.gov/pubmed/2558410"
                                                             target="_blank"  rel="noreferrer">PMID: 2558410</a>
                                    <br/>
                                </p>
                                <br/>
                            </div>
                        </section>
                </Grid>

            </Grid>
            <CustomHorizontalSpace space={300}/>
        </div>

    );
}

export default Publications;