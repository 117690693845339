import { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';

const useForm = (callback, validate , form) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        message: '',
        privacy: false,
    });
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = e => {
        e.preventDefault();
        setErrors(validate(values));
        setIsSubmitting(true);
    };

    useEffect(
        () => {
            if (Object.keys(errors).length === 0 && isSubmitting) {
                emailjs.sendForm('service_hfhgpzj', 'template_vmqiifl', form.current , 'user_eY86lnROZT15LXWFMA5Cb').then((result) => {
                    console.log('result ' + result.text);
                }, (error) => {
                    console.log(error.text);
                });
                callback();
            }
        },
        [callback, errors, form, isSubmitting]
    );

    return { handleChange, handleSubmit, values, errors };
};

export default useForm;