import * as React from 'react';
import {Card, CardContent, Grid } from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import circle from "../SVG/Circle-30.svg"
import CustomHorizontalSpace from "../Assistants/SpaceAssistant";

function PropertyCard(props) {
    return (
        <>
            <Card sx={{ minWidth: 275 }} style={{webKidBoxShadow: '5px 5px 11px 0px rgba(0,0,0,0.3)',
                boxShadow: '5px 5px 11px 0px rgba(0,0,0,0.3)', minWidth:'220px' , maxWidth:'220px', minHeight:'300px'}}>
                <CardContent>
                    <Grid container style={{display:'flex',justifyContent:'center',alignItems:'center' , minHeight:'100px' , backgroundImage:`url(${circle})`}}>
                        {props.icon ? <FontAwesomeIcon icon={props.icon} color={props?.color} size={'4x'}/> : <img src={props.svg} width={props.svgWidth ? props.svgWidth : 80} alt={'logo'}/>}

                    </Grid>
                    <h3 align={'center'}>
                        {props.title}
                    </h3>
                    <CustomHorizontalSpace space={10}/>
                    <p style={{color:'#7a7b7f'}} align={'center'}>
                        {props.text}
                    </p>
                </CardContent>
            </Card>
        </>
    );
}

export default PropertyCard;