import React from 'react';
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import Title from "../../Title/Title";
import justicia from "../../../resources/images/tingey-injury-law-firm-yCdPU73kGSc-unsplash.jpg.webp"

function LegalNotice() {
    return <>
        <Title title={'Legal Notice'} img={justicia}/>
        <CustomHorizontalSpace space={100}/>
        <div className="headline" style={{width: '70%', marginLeft: '10%'}}>
            <h1 className="adsimple-321289265">Impressum</h1>
            <br/>
            <p className="adsimple-321289265">Informationspflicht laut § 5 TMG.</p>
            <p className="adsimple-321289265">BioRetis GmbH<br/>Warägerweg 10, <br/>13595 Berlin, <br/>Deutschland
            </p>
            <br/>
            <p className="adsimple-321289265">
                <strong>UID-Nummer:</strong> DE 253 064 266<br/>
                <strong>Wirtschafts-ID:</strong> 37/230/21168<br/>
                <strong>Register:</strong> Handelsregister<br/>
                <strong>Registernummer:</strong> HRB 105308 B<br/>
                <strong>Registergericht:</strong> Amtsgericht Berlin (Charlottenburg)<br/>
            </p>
            <br/>
            <p className="adsimple-321289265">
                <strong>E-Mail:</strong> <a href="mailto:mail@bioretis.com">mail@bioretis.com</a>
            </p>
            <br/>
            <p className="adsimple-321289265">
                <strong>Geschäftsführer</strong>
                <br/>Rainer Seubert</p>
            <br/>
            <strong>Kontaktdaten des Verantwortlichen für Datenschutz</strong>
            <br/>
            <p className="adsimple-321289265">Sollten Sie Fragen zum Datenschutz haben, finden Sie
                nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br/>
                <br/>
                BioRetis GmbH <br/>
                Warägerweg 10<br/>
                13595 Berlin<br/>
                <br/>
                <br/>Vertretungsberechtigt: Bettina Johannes<br/>E-Mail-Adresse: <a
                    href="mailto:bettina.johannes@bioretis.com">bettina.johannes@bioretis.com</a>
                <br/>
            </p><br/>
            <p>Quelle: Erstellt mit dem <a
                href="https://www.adsimple.de/impressum-generator/">Impressum Generator</a> von AdSimple
            </p>
            <br/>
            <h2 id="eu-streitschlichtung" className="adsimple-321289265">EU-Streitschlichtung</h2>
            <br/>
            <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
                (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform
                (OS-Plattform) informieren.<br/>
                Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform
                der Europäischen Kommission unter <a className="adsimple-321289265"
                                                     href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
                                                     target="_blank"
                                                     rel="noreferrer">http://ec.europa.eu/odr?tid=321289265</a> zu
                richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in unserem Impressum.
            </p>
            <br/>
            <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
                Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <br/>
            <h2 id="haftungs-inhalte-website" className="adsimple-321289265">Haftung für Inhalte dieser
                Website</h2>
            <br/>
            <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und
                aktuelle Informationen bereitzustellen. Laut Telemediengesetz <a
                    className="adsimple-321289265"
                    href="https://www.gesetze-im-internet.de/tmg/__7.html?tid=321289265"
                    rel="noreferrer"
                    target="_blank">(TMG) §7 (1)</a> sind wir als Diensteanbieter für eigene
                Informationen, die wir zur Nutzung bereitstellen, nach den allgemeinen Gesetzen
                verantwortlich. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf
                dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.
                Als Diensteanbieter im Sinne der §§ 8 bis 10 sind wir nicht verpflichtet, die von ihnen
                übermittelten oder gespeicherten Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p><br/>
            <p>Unsere Verpflichtungen zur Entfernung von Informationen oder zur Sperrung der Nutzung von
                Informationen nach den allgemeinen Gesetzen aufgrund von gerichtlichen oder behördlichen
                Anordnungen bleiben auch im Falle unserer Nichtverantwortlichkeit nach den §§ 8 bis 10
                unberührt. </p><br/>
            <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns
                umgehend zu kontaktieren, damit wir die rechtswidrigen Inhalte entfernen können. Sie
                finden die Kontaktdaten im Impressum.</p><br/>
            <h2 id="haftung-links-website" className="adsimple-321289265">Haftung für Links auf dieser
                Website</h2><br/>
            <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht
                verantwortlich sind. Haftung für verlinkte Websites besteht für uns nicht, da wir keine
                Kenntnis rechtswidriger Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch
                bisher nicht aufgefallen sind und wir Links sofort entfernen würden, wenn uns
                Rechtswidrigkeiten bekannt werden.</p><br/>
            <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir Sie uns zu
                kontaktieren. Sie finden die Kontaktdaten im Impressum.</p><br/>
            <h2 id="urheberrechtshinweis" className="adsimple-321289265">Urheberrechtshinweis</h2><br/>
            <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht
                der Bundesrepublik Deutschland. Bitte fragen Sie uns bevor Sie die Inhalte dieser
                Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf anderen Websites
                erneut veröffentlichen. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen
                der Inhalte unserer Seite rechtlich verfolgen.</p><br/>
            <p>Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten
                wir Sie uns zu kontaktieren.</p><br/>
            <h2 id="bildernachweis" className="adsimple-321289265">Bildernachweis</h2><br/>
            <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
            <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p><br/>
            <ul className="adsimple-321289265">
                <li>
                    Berlin Picture by <a href="https://unsplash.com/@florianwehde?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Florian Wehde</a> on <a href="https://unsplash.com/s/photos/berlin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </li>
                <li>Cluster Picture by <a href="https://unsplash.com/@melpoole?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Mel Poole</a> on <a href="https://unsplash.com/s/photos/cluster?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </li>
                <li> Books Picture by <a href="https://unsplash.com/@syinq?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Susan Q Yin</a> on <a href="https://unsplash.com/s/photos/books?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </li>
                <li>Picture Woman by <a href="https://pixabay.com/de/users/roverhate-1759589/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1353825">Ronny Overhate</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1353825">Pixabay</a></li>
                <li>Picture Man by <a href="https://pixabay.com/de/users/roverhate-1759589/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1352025">Ronny Overhate</a> auf <a href="https://pixabay.com/de/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1352025">Pixabay</a></li>
                <li>Justicia Photo by <a href="https://unsplash.com/@tingeyinjurylawfirm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Tingey Injury Law Firm</a> on <a href="https://unsplash.com/s/photos/legal?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </li>
                <li> <a href='https://www.freepik.com/vectors/information'>Information vector created by macrovector - www.freepik.com</a></li>
                <li> Lock Photo by <a href="https://unsplash.com/@imattsmart?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">iMattSmart</a> on <a href="https://unsplash.com/s/photos/lock?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>
                </li>


            </ul><br/>

            <p>Alle Texte sind urheberrechtlich geschützt.</p>
            <p>Quelle: Erstellt mit dem <a
                href="https://www.adsimple.de/datenschutz-generator/">Datenschutz Generator</a> von
                AdSimple</p>
        </div>
        <CustomHorizontalSpace space={100}/>
    </>
}

export default LegalNotice;