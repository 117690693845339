import React, {useState} from 'react';
import FormSignup from "./FormSignup";
import FormSuccess from "./FormSuccess";

function AltContactForm(props) {

    const [isSubmitted, setIsSubmitted] = useState(false);
    function submitForm() {
        setIsSubmitted(true);
    }
    return (
        <>
            <div className='form-container' id={'contact'}>
                {!isSubmitted ? (
                    <FormSignup submitForm={submitForm} />
                ) : (
                    <FormSuccess />
                )}
            </div>
        </>
    );
}
export default AltContactForm;