import React from "react";
import {Grid} from "@mui/material";
import {Button} from "../../Buttons/Button";
import {Colors} from "../../../resources/Colors";


export function GoToDemoSection(){

    return <>
        <Grid container style={{backgroundColor: Colors.bioretisBlueLight , minHeight:'30vh'}} alignItems={'center'} direction={"column"} justifyContent={'center'} spacing={5} >
            <Grid item xs={12} style={{marginLeft:'10%' , marginRight:'10%'}}>
                <h1 className={'go_to_demo_h1'}>Do you want to see more about immunoClust?</h1>
            </Grid>
            <Grid item xs={12} style={{marginBottom:'10%'}}>
                <Button buttonStyle={"btn--outline--dark"} buttonSize={'btn--large'} onClick={() => window.location.href='/immunoclust-demo'} >show me more</Button>
            </Grid>

        </Grid>


    </>
}export default GoToDemoSection