import React from 'react';
import '../../App.css';
import {Button} from '../Buttons/Button';
import './HeroSection.css';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';


function HeroSection() {
    return (
        <div className='hero-container' id={'home'}>

            <h1>BioRetis GmbH</h1>

            <p className={"slogan"}>Bioinformatics | Research | Development | Statistics | AI | Web-Applications | Mobile Applications</p>


            <div className='hero-btns'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                    onClick={() => window.location.href='/about'}
                >
                    ABOUT
                </Button>
                <Button
                    className='btns'
                    buttonStyle='btn--primary'
                    buttonSize='btn--large'
                    onClick={() => window.location.href='/immunoclust-demo'}
                >
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                        ICAP Demo
                        <div style={{display:"flex", justifyContent:"center", alignItems:"center" , marginLeft:"5px"}}>
                            <PlayCircleIcon style={{backgroundColor:"transparent"}} /><
                        /div>
                    </div>
                </Button>
            </div>

        </div>
    );
}

export default HeroSection;