import React from "react";
import CustomHorizontalSpace from "../../Assistants/SpaceAssistant";
import {Grid, Hidden} from "@mui/material";
import bg from "../../SVG/Circle-230-dna.svg"
import imageUrl from "../../../resources/images/dna.png"
import {AnimationOnScroll} from "react-animation-on-scroll";
import "./Homepage.css"


export function HeroImmunoClust() {

    return <>
        <h1 align={'center'} style={{marginTop: '50px'}}>
            immunoClust - the FUTURE of cytometry
        </h1>
        <CustomHorizontalSpace space={50}/>
        <Grid container>
            <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <Grid container direction={'column'}>
                    <AnimationOnScroll animateIn={"animate__fadeInLeftBig"}>
                        <Grid item xs={12} style={{marginLeft: '10%', marginBottom: '30px'}}>
                            <div className={"line"}></div>
                            <h2> immunoClust</h2>
                        </Grid>
                        <Grid item xs={12} style={{marginLeft: '10%', marginRight: '25%'}}>
                            <p>
                                <b>immunoClust</b> immunoClust presents an <b>automated analysis pipeline</b> for
                                uncompensated fluorescence and mass cytometry data and consists of two parts. </p><br/>
                            <p>First, cell events of each sample are grouped into individual clusters (cell-clustering).
                                Subsequently, a classification algorithm assorts these cell event clusters into
                                populations
                                comparable between different samples (meta-clustering). </p><br/>
                            <p>The clustering of cell events is designed for datasets with large event counts in high
                                dimensions as a global unsupervised method, sensitive to identify rare cell types even
                                when
                                next to large populations.</p><br/>
                            <p>
                                Both parts use model-based clustering with an iterative Expectation Maximization (EM)
                                algorithm and the Integrated Classification Likelihood (ICL) to obtain the clusters.</p>
                            <br/>

                        </Grid>
                    </AnimationOnScroll>
                    <CustomHorizontalSpace space={30}/>

                    <AnimationOnScroll animateIn={"animate__fadeInRightBig"}>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '30px',
                            marginRight: '10%'
                        }}>
                            <div style={{marginLeft: '10%', marginBottom: '30px'}}>
                                <div className={"line"}></div>
                                <h2> immunoClust cell-clustering</h2>
                            </div>

                        </Grid>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            marginBottom: '30px',
                            marginRight: '10%',

                        }}>
                            <p className={'icap_p'} >
                                The cell-clustering process fits a mixture model with t-distributions. </p><br/>
                            <p className={'icap_p'} > Within the clustering process a optimisation of the
                                asinh-transformation for the fluorescence parameters is
                                included.
                            </p><br/>
                        </Grid>
                    </AnimationOnScroll>
                    <CustomHorizontalSpace space={30}/>


                    <AnimationOnScroll animateIn={"animate__fadeInLeftBig"}>
                        <Grid item xs={12} style={{marginLeft: '10%', marginBottom: '30px'}}>
                            <div className={"line"}></div>
                            <h2> immunoClust meta-clustering</h2>
                        </Grid>
                        <Grid item xs={12} style={{marginLeft: '10%', marginRight: '25%'}}>
                            <p>
                                The meta-clustering fits a Gaussian mixture model for the meta-clusters, where adjusted
                                Bhattacharyya-Coefficients give the probability measures between cell- and
                                meta-clusters. </p><br/>
                            <p>Several plotting routines are available visualising the results of the cell- and
                                meta-clustering process. Additional helper-routines to extract population features are
                                provided.</p><br/>


                        </Grid>
                    </AnimationOnScroll>
                    <CustomHorizontalSpace space={30}/>
                </Grid>
            </Grid>
            <Hidden mdDown>
                <Grid item xs={12} md={6} style={{
                    backgroundImage: `url(${bg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: 'center'
                }}>
                    <img src={imageUrl} width={300} alt={'dna'}/>
                </Grid>
            </Hidden>
        </Grid>
    </>
}

export default HeroImmunoClust